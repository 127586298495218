import { NewTranslationDtoReq } from "@/shared/api/generated.ts";
import axios from "axios";
import { LANG_KEYS } from "@/shared/constants";

class DictionaryPaths {
  static BASE_URL = import.meta.env.VITE_API_URL + "/api";
  static GET = (lang: string) =>
    `${this.BASE_URL}/translation/dictionary/${lang}`;
  static NEW = `${this.BASE_URL}/translation/new`;
}

export const getDictionary = async (lang: string) => {
  try {
    const { data } = await axios.get<Record<string, string>>(
      DictionaryPaths.GET(lang),
    );
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const newDictionaryKey = async (dto: NewTranslationDtoReq) => {
  try {
    await axios.post(DictionaryPaths.NEW, dto);
  } catch (e) {
    console.log(e);
  }
};

export const translateAllKeys = async () => {
  const keys = Object.keys(LANG_KEYS);
  for (const key of keys) {
    const item = LANG_KEYS[key] as NewTranslationDtoReq;
    await newDictionaryKey(item);
  }
};
