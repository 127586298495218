import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion as m } from "framer-motion";
import styles from "./styles.module.scss";
import { useUserStore } from "@/entities";
import { useEffect } from "react";
import { ROUTER_PATHS } from "@/shared/constants";

export const UiAccountLayout = () => {
  const location = useLocation();

  const { email } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(ROUTER_PATHS.HOME, { replace: true });
    }
  }, [email]);

  if (!email) return null;

  return (
    <AnimatePresence mode="wait">
      <m.main
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className={styles.container}
      >
        <div className={styles.content}>
          <Outlet />
        </div>
      </m.main>
    </AnimatePresence>
  );
};
