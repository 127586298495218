import {
  useAutoTranslation,
  useRecordOptionsModalStore,
  useRecordOptionsStore,
} from "@/entities";
import { capitalize } from "@/shared/utils";
import styled from "styled-components";
import { LANG_KEYS } from "@/shared/constants";

export const OptionsSettings = () => {
  const recordOptionsStore = useRecordOptionsStore();
  const { showModal } = useRecordOptionsModalStore();
  const { t } = useAutoTranslation();

  return (
    <>
      <TriggerWrapper>
        <div>
          {t(LANG_KEYS.SUMMARY_LANGUAGE.key, LANG_KEYS.SUMMARY_LANGUAGE.value)}:
        </div>
        <TriggerButton type="button" onClick={showModal}>
          {capitalize(
            `${t(
              `LANG_NAME_${recordOptionsStore.notesLang}_KEY`,
              `${recordOptionsStore.notesLang}`,
            )} (${t(LANG_KEYS.EDIT.key, LANG_KEYS.EDIT.value)})`,
          )}
        </TriggerButton>
      </TriggerWrapper>
    </>
  );
};

const TriggerWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.125rem;
  align-items: center;
  color: rgb(75, 85, 99);
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const TriggerButton = styled.button`
  text-decoration: underline;
`;
