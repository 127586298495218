import { useForm } from "react-hook-form";
import { useTextNoteStore } from "@/entities";

type FormData = { original: string };
export function useTextNoteForm() {
  const { setOriginal } = useTextNoteStore();
  const { control, reset, handleSubmit } = useForm<FormData>({
    defaultValues: {
      original: "",
    },
  });

  return {
    control,
    reset,
    handleSubmit: handleSubmit((values) => {
      setOriginal({
        original: values.original.trim(),
      });
    }),
  };
}
