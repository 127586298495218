export type StepType = {
  data: {
    key: string;
    value: string;
  };
  done: boolean;
  text: string;
};

export const markDone = (oldData: StepType[], idx: number, text: string) => {
  const clone = [...oldData];
  clone[idx].done = true;
  clone[idx].text = text;
  return clone;
};

export const updateText = (oldData: StepType[], idx: number, text: string) => {
  const clone = [...oldData];
  clone[idx].text = text;
  return clone;
};
