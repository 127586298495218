import { ResetRequestPasswordForm } from "@/features";
import { LANG_KEYS } from "@/shared/constants";
import { AuthWidget } from "@/widgets";
import { useAutoTranslation } from "@/entities";

export const RequestResetPasswordPage = () => {
  const { t } = useAutoTranslation();
  return (
    <AuthWidget
      title={t(
        LANG_KEYS.RESET_YOUR_PASSWORD.key,
        LANG_KEYS.RESET_YOUR_PASSWORD.value,
      )}
      heading={t(LANG_KEYS.NOT_A_MEMBER.key, LANG_KEYS.NOT_A_MEMBER.value)}
      text={t(LANG_KEYS.SIGN_UP_NOW.key, LANG_KEYS.SIGN_UP_NOW.value)}
      href="/auth/sign-up"
    >
      <ResetRequestPasswordForm />
    </AuthWidget>
  );
};
