import { UiButton } from "@/shared/ui";
import { useAutoTranslation } from "@/entities";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import { userControllerDisconnectNotion } from "@/shared/api/generated.ts";
import { useQueryClient } from "@tanstack/react-query";

export const DisconnectNotion = () => {
  const { t } = useAutoTranslation();
  const queryClient = useQueryClient();

  const handleDisconnect = async () => {
    await userControllerDisconnectNotion();
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.NOTION_DATA,
    });
  };

  return (
    <UiButton variant="danger" onClick={handleDisconnect}>
      <div>
        {t(LANG_KEYS.DISCONNECT.key, LANG_KEYS.DISCONNECT.value)}&nbsp;Notion
      </div>
    </UiButton>
  );
};
