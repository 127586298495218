export type SelectOption = {
  value: string;
  name: string;
};

// export type LangOption = {
//   name: string;
//   nativeName: string;
//   isReferenceLanguage: boolean;
//   code: string;
// };

export enum UserPlan {
  "FREE" = "FREE",
  "ADVANCED" = "ADVANCED",
  "PRO" = "PRO",
  "UNREGISTERED" = "UNREGISTERED",
}

export type UserPayload = {
  id: string;
  email: string;
  isEmailConfirmed: boolean;
  maxRecordTime: number;
  plan: UserPlan;
};

export enum TranscribeEnum {
  FILE = "FILE",
  RECORD = "RECORD",
}
