import { UiButton, UiPaper } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import styles from "./styles.module.scss";
import styled from "styled-components";
import { IconDanger } from "@/shared/ui/icons";
import { capitalize } from "@/shared/utils";
import { useDeleteAccountForm } from "./model.ts";
import { useAutoTranslation, useDeleteAccountModalStore } from "@/entities";

export const DeleteAccount = () => {
  const { t } = useAutoTranslation();
  const { isLoading, handleSubmit } = useDeleteAccountForm();
  const deleteAccountModalStore = useDeleteAccountModalStore();

  const handleClose = () => {
    if (isLoading) return;
    // BodyNoScroll.Disable();
    deleteAccountModalStore.closeModal();
  };

  return (
    <>
      <UiPaper className={styles.deleteAccountCard}>
        <CardInner>
          <IconWrapper>
            <IconDanger className="h-6 w-6 text-red-600" />
          </IconWrapper>
          <CardContent>
            <CardTitle>
              {capitalize(
                t(
                  LANG_KEYS.DEACTIVATE_ACCOUNT.key,
                  LANG_KEYS.DEACTIVATE_ACCOUNT.value,
                ),
              )}
            </CardTitle>
            <CardText>
              {t(
                LANG_KEYS.ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT.key,
                LANG_KEYS.ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT
                  .value,
              )}
            </CardText>
            <CardFooter>
              <FooterInner>
                <UiButton type="button" variant="white" onClick={handleClose}>
                  <div>
                    {capitalize(
                      t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value),
                    )}
                  </div>
                </UiButton>
                <UiButton
                  type="button"
                  variant="danger"
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  <div>
                    {capitalize(
                      t(LANG_KEYS.DELETE.key, LANG_KEYS.DELETE.value),
                    )}
                  </div>
                </UiButton>
              </FooterInner>
            </CardFooter>
          </CardContent>
        </CardInner>
      </UiPaper>
    </>
  );
};

const CardInner = styled.div`
  display: flex;
  gap: 16px;
`;
const CardContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
`;
const CardFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`;
const FooterInner = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const IconWrapper = styled.div`
  background-color: #fee2e2;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled.h3`
  color: #111827;
  font-weight: 600;
  font-size: 16px;
`;
const CardText = styled.p`
  color: #6b7280;
  font-size: 14px;
`;
