import { FC } from "react";
import styled, { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

interface Props {
  hoverEffect?: boolean;
  fontSize?: number;
}

export const UiLogo: FC<Props> = ({ hoverEffect = true, fontSize = 24 }) => {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <UiLogoContainer hoverEffect={hoverEffect}>
        <LogoImg src="/logo.svg" alt="AudioNotes Logo" />
        <Title className="title" $fontSize={fontSize}>
          AudioNotes.ai
        </Title>
      </UiLogoContainer>
    </StyleSheetManager>
  );
};

const UiLogoContainer = styled.div<{ hoverEffect: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 575px) {
    gap: 5px;
  }

  ${({ hoverEffect }) =>
    hoverEffect &&
    `
    cursor: pointer;
    &:hover .title {
      color: #0056b3;
      text-decoration: underline;
    }
  `}
`;

const LogoImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`;

const Title = styled.h4<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: 700;
  color: black;

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
`;
