import { ConnectZapier } from "./connect-zapier.tsx";

export const IntegrationZapier = () => {
  return <ConnectZapier />;

  // return data.zapierWebhookConnection ? (
  //   <DisconnectZapier />
  // ) : (
  //   <ConnectZapier />
  // );
};
