import styled from "styled-components";
import { UiInputSearch } from "@/shared/ui";
import { useAutoTranslation, useSearchNotesStore } from "@/entities";
import { LANG_KEYS } from "@/shared/constants";
import { useSearchNoteForm } from "./model.ts";
import { Controller } from "react-hook-form";
import { useEffect, useRef } from "react";

export const SearchNote = () => {
  const { t } = useAutoTranslation();
  const { handleSubmit, control, setValue } = useSearchNoteForm();
  const { closeModal } = useSearchNotesStore();
  const inputRef = useRef<HTMLInputElement>();

  const handleClear = () => {
    setValue("searchSavedNotes", "");
  };

  const handleKeyboardPress = (e: any) => {
    if (e.keyCode == 27 || e.key == "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPress);
    // BodyNoScroll.Enable();
    return () => {
      window.removeEventListener("keydown", handleKeyboardPress);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Backdrop>
      <Wrapper onClick={closeModal}>
        <form
          onClick={(e) => e.stopPropagation()}
          className="h-[56px]"
          onSubmit={handleSubmit}
        >
          <Controller
            control={control}
            render={({ field }) => (
              <UiInputSearch
                ref={inputRef as any}
                onChange={(e) => field.onChange(e.target.value)}
                onSearch={handleSubmit}
                onClear={handleClear}
                name={field.name}
                value={field.value}
                placeholder={
                  t(LANG_KEYS.QUICK_SEARCH.key, LANG_KEYS.QUICK_SEARCH.value) +
                  "..."
                }
              />
            )}
            name="searchSavedNotes"
          />
        </form>
      </Wrapper>
    </Backdrop>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  height: 100%;
`;
