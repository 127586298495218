import { create } from "zustand";
import { devtools } from "zustand/middleware";

type OpenEditPayload = {
  noteId: string;
};

type Store = {
  noteId: string | null;
  openChat: (payload: OpenEditPayload) => void;
  closeChat: () => void;
};

export const useChatNoteStore = create<Store>()(
  devtools(
    (set) => ({
      noteId: null,
      openChat: (payload: OpenEditPayload) => {
        set(() => ({
          noteId: payload.noteId,
        }));
      },
      closeChat: () => {
        // BodyNoScroll.Disable();
        set(() => ({
          noteId: null,
        }));
      },
    }),
    { name: "ChatNoteStore" },
  ),
);
