import {
  forwardRef,
  PropsWithRef,
  ReactNode,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { IoSend } from "react-icons/io5";
import { UiSpinner } from "@/shared/ui/ui-spinner";

type Props = {
  name: string;
  value?: string;
  error?: string;
  placeholder?: string;
  onSend?: () => void;
  isLoading?: boolean;
  infoText?: ReactNode;
} & PropsWithRef<TextareaHTMLAttributes<HTMLTextAreaElement>>;

export const UiInputChat = forwardRef<HTMLTextAreaElement, Props>(
  ({ placeholder = "", onSend, name, value, isLoading, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleKeyboardPress = (e: any) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (onSend && !isLoading) {
          onSend();
        }
      }
    };

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.addEventListener("keydown", handleKeyboardPress);
      }
      return () => {
        if (textareaRef.current) {
          textareaRef.current.removeEventListener(
            "keydown",
            handleKeyboardPress,
          );
        }
      };
    }, [textareaRef.current, onSend, isLoading]);

    useEffect(() => {
      if (ref && typeof ref === "function") {
        ref(textareaRef.current);
      } else if (ref && "current" in ref) {
        ref.current = textareaRef.current;
      }
    }, [ref]);

    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    const handleSend = () => {
      if (!onSend) return;
      if (isLoading) return;
      onSend();
    };

    return (
      <Wrapper>
        <Input
          ref={textareaRef}
          value={value}
          name={name}
          id={name}
          placeholder={placeholder}
          {...props}
          onInput={handleInput}
        />

        <ButtonContainer $right="20px">
          <IconButton $loading={!!isLoading} onClick={handleSend} type="button">
            {!isLoading ? <IoSend color="#000" size={26} /> : <UiSpinner />}
          </IconButton>
        </ButtonContainer>
      </Wrapper>
    );
  },
);

UiInputChat.displayName = "UiInputChat";

const Wrapper = styled.div`
  position: relative;
  height: auto;
`;

const Input = styled.textarea`
  outline: none;
  font-size: 14px;
  width: 100%;
  box-shadow: 1px 1px 5px 0 gray;
  border: 1px solid gray;
  border-radius: 16px;
  resize: none;
  max-height: 320px;
  height: 58px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 40px 8px 8px;
`;

const ButtonContainer = styled.div<{ $right: string }>`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50%));
  right: ${(props) => props.$right};
`;

const IconButton = styled.button<{ $loading: boolean }>`
  &:hover + div {
    ${(props) => !props.$loading && `display: block;`}
  }
`;

// const Info = styled.div`
//   display: block;
//   position: absolute;
//   top: -100%;
//   left: -15px;
//   background: #eeeeee;
//   padding: 20px;
//   border: 1px solid #808080;
//   border-radius: 16px;
//   white-space: pre-wrap;
//   z-index: 1;
// `;
