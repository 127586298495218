import { AnimatePresence, motion as m } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";

export const UiUserLayout = () => {
	const location = useLocation();
	return (
		<AnimatePresence mode="wait">
			<m.main
				key={location.pathname}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.75, ease: "easeOut" }}
				exit={{ opacity: 1 }}
				className="bg-[#F3F4F6] h-screen overflow-y-auto flex justify-center items-center"
			>
				<Outlet />
			</m.main>
		</AnimatePresence>
	);
};
