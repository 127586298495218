import { useRecordOptionsStore } from "@/entities";
import {
  AddCustomSummaryStyleDtoReq,
  userControllerAddCustomSummaryStyle,
} from "@/shared/api/generated";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

type FormData = AddCustomSummaryStyleDtoReq;
export function useAddCustomPromptForm(cb?: (selectedId: string) => void) {
  const recordOptionsStore = useRecordOptionsStore();

  const formMutation = useMutation({
    mutationFn: userControllerAddCustomSummaryStyle,
    async onSuccess(res) {
      recordOptionsStore.setSummaryStyle(res.summaryStyle);
      if (cb) {
        cb(res.createdId);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  return {
    register,
    errors,
    isLoading: formMutation.isPending,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
  };
}
