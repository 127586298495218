import { useLocation, useNavigate } from "react-router-dom";
import { UiLoading } from "@/shared/ui";
import { useEffect } from "react";
import { useUserStore } from "@/entities";
import { ROUTER_PATHS } from "@/shared/constants";

export const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const userStore = useUserStore();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const jwt = queryParams.get("jwt");
    if (jwt) {
      userStore.login(jwt);
    }
    navigate(ROUTER_PATHS.HOME, { replace: true });
  }, []);

  return <UiLoading fullScreen />;
};
