import { UiButton } from "@/shared/ui";
import { useAutoTranslation, useUserStore } from "@/entities";
import { LANG_KEYS } from "@/shared/constants";

export const ConnectNotion = () => {
  const { t } = useAutoTranslation();

  const { email } = useUserStore();

  const connectUrl = `${import.meta.env.VITE_NOTION_AUTH_URL}`.replace(
    "USER_STATE",
    email,
  );

  return (
    <a href={connectUrl}>
      <UiButton variant="main">
        <div>
          {t(LANG_KEYS.CONNECT.key, LANG_KEYS.CONNECT.value)}&nbsp;Notion
        </div>
      </UiButton>
    </a>
  );
};
