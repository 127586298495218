import styled from "styled-components";
import { LANG_KEYS, LOCAL_STORAGE_KEYS, QUERY_KEYS } from "@/shared/constants";
import {
  appleProvider,
  firebaseAuth,
  googleProvider,
  useAutoTranslation,
  useRecordOptionsStore,
  useUserStore,
} from "@/entities";
import { signInWithPopup } from "firebase/auth";
import {
  AppleAuthDtoReq,
  authControllerAppleAuth,
  authControllerGoogleAuth,
  GoogleAuthDtoReq,
} from "@/shared/api/generated.ts";
import { useState } from "react";
import { UiSpinner } from "@/shared/ui";
import { useQueryClient } from "@tanstack/react-query";

export const SocialAuth = () => {
  const { t } = useAutoTranslation();
  const { login } = useUserStore();
  const { invalidateOptions } = useRecordOptionsStore();

  const queryClient = useQueryClient();

  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);

  const handleAuthorization = async (token: string) => {
    login(token);
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
    await invalidateOptions();
  };

  const handleGoogleSignIn = async () => {
    try {
      setGoogleLoading(true);
      const result = await signInWithPopup(firebaseAuth, googleProvider);
      const token = await result.user.getIdToken();
      const telegramId = localStorage.getItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      const payload: GoogleAuthDtoReq = { token };

      if (telegramId) {
        payload.telegramId = telegramId;
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      }

      const { access_token, telegramLink } =
        await authControllerGoogleAuth(payload);
      await handleAuthorization(access_token);

      if (telegramLink) {
        window.open(telegramLink, "_blank", "noopener,noreferrer");
      }

      setGoogleLoading(false);
    } catch (e) {
      console.log(e);
      setGoogleLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      setAppleLoading(true);
      const result = await signInWithPopup(firebaseAuth, appleProvider);
      const token = await result.user.getIdToken();

      const telegramId = localStorage.getItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      const payload: AppleAuthDtoReq = { token };

      if (telegramId) {
        payload.telegramId = telegramId;
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      }
      const { access_token, telegramLink } =
        await authControllerAppleAuth(payload);
      await handleAuthorization(access_token);

      if (telegramLink) {
        window.open(telegramLink, "_blank", "noopener,noreferrer");
      }

      setAppleLoading(false);
    } catch (e) {
      console.log(e);
      setAppleLoading(false);
    }
  };

  return (
    <Wrapper>
      <Top>
        <Line className="flex-1" />
        <Text>
          {t(LANG_KEYS.OR_CONTINUE_WITH.key, LANG_KEYS.OR_CONTINUE_WITH.value)}
        </Text>
        <Line className="flex-1" />
      </Top>

      <Content>
        <GoogleBtn type="button" onClick={handleGoogleSignIn}>
          {googleLoading ? (
            <UiSpinner />
          ) : (
            <>
              <img src="/logo-google.svg" alt="#" />
              <span>Google</span>
            </>
          )}
        </GoogleBtn>
        <AppleBtn type="button" onClick={handleAppleSignIn}>
          {appleLoading ? (
            <UiSpinner />
          ) : (
            <>
              <img src="/logo-apple.svg" alt="#" />
              <span>Sign in with Apple</span>
            </>
          )}
        </AppleBtn>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const Text = styled.div`
  color: #111827;
  font-weight: 500;
  font-size: 14px;
`;

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainBtn = styled.button`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #000;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  span {
    font-weight: 600;
    font-size: 14px;
  }
`;

const GoogleBtn = styled(MainBtn)`
  background-color: #f9f9f9;
`;

const AppleBtn = styled(MainBtn)`
  background-color: #000;
  color: #fff;
`;
