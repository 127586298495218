import styled, { css, StyleSheetManager } from "styled-components";
import { FC, ReactNode } from "react";
import isPropValid from "@emotion/is-prop-valid";

type Variant = "dark" | "purple";
interface Props {
  children: ReactNode;
  variant?: Variant;
  linkHover?: boolean;
  fontSize?: number;
  box?: boolean;
  className?: string;
}

export const UiLink: FC<Props> = ({
  linkHover = true,
  variant = "dark",
  fontSize = 14,
  box,
  children,
  className,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <Wrapper
        variant={variant}
        linkHover={linkHover}
        fontSize={fontSize}
        box={box}
        className={className}
      >
        {children}
      </Wrapper>
    </StyleSheetManager>
  );
};

const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 600;

  ${({ box }) =>
    box &&
    css`
      padding: 10px 16px;
      border-radius: 5px;

      &:hover {
        background-color: #f9fafb;
      }
    `}

  ${({ variant, linkHover }) =>
    variant === "dark" &&
    css`
      color: var(--dark);

      ${!!linkHover &&
      css`
        &:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      `}
    `}

  ${({ variant }) =>
    variant === "purple" &&
    css`
      color: var(--main-purple);

      &:hover {
        color: var(--main-purple-hover);
      }
    `}
`;
