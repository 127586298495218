import { create } from "zustand";
import { devtools } from "zustand/middleware";

type SetOriginalPayload = {
  original: string;
};

type Store = {
  open: boolean;
  original: string;
  openTextNote: () => void;
  closeTextNote: () => void;
  clearOriginal: () => void;
  setOriginal: ({ original }: SetOriginalPayload) => void;
};

export const useTextNoteStore = create<Store>()(
  devtools(
    (set, getState) => ({
      open: false,
      original: "",
      setOriginal: ({ original }: SetOriginalPayload) => {
        set(() => ({
          original: original,
          open: false,
        }));
        // BodyNoScroll.Disable();
      },
      clearOriginal: () => {
        set(() => ({
          original: "",
        }));
      },
      openTextNote: () => {
        const currentState = getState();
        if (!currentState.original) {
          set(() => ({
            open: true,
          }));
        }
      },
      closeTextNote: () => {
        // BodyNoScroll.Disable();
        set(() => ({
          open: false,
        }));
      },
    }),
    { name: "TextNoteStore" },
  ),
);
