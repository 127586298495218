import { useRecordOptionsStore } from "@/entities";
import {
  EditRecordOptionsDtoReq,
  userControllerEditRecordOptions,
} from "@/shared/api/generated";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

type FormData = EditRecordOptionsDtoReq;
export function useRecordOptionsForm(cb?: () => void) {
  const recordOptionsStore = useRecordOptionsStore();

  const formMutation = useMutation({
    mutationFn: userControllerEditRecordOptions,
    async onSuccess(res) {
      recordOptionsStore.setOptions(res);
      if (cb) {
        cb();
      }
    },
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      notesLang: recordOptionsStore.notesLang,
      numberOfSpeakers: recordOptionsStore.numberOfSpeakers,
      summaryLength: recordOptionsStore.summaryLength,
      summaryView: recordOptionsStore.summaryView,
      selectedRecordSummaryStyleId:
        recordOptionsStore.selectedRecordSummaryStyleId,
    },
  });

  useEffect(() => {
    setValue(
      "selectedRecordSummaryStyleId",
      recordOptionsStore.selectedRecordSummaryStyleId,
    );
  }, [recordOptionsStore.selectedRecordSummaryStyleId]);

  return {
    setValue,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
    defaultValues: getValues(),
    getValues,
    control,
    watch,
  };
}
