import {
  useDeleteAccountModalStore,
  useRecordOptionsStore,
  useUserStore,
} from "@/entities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userControllerDeleteMe } from "@/shared/api/generated.ts";
import { QUERY_KEYS } from "@/shared/constants";

export function useDeleteAccountForm() {
  const userStore = useUserStore();
  const queryClient = useQueryClient();
  const { invalidateOptions } = useRecordOptionsStore();
  const deleteAccountModalStore = useDeleteAccountModalStore();

  const formMutation = useMutation({
    mutationFn: userControllerDeleteMe,
    async onSuccess() {
      userStore.logout();
      deleteAccountModalStore.closeModal();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
      await invalidateOptions();
    },
  });

  return {
    handleSubmit: () => formMutation.mutate({}),
    isLoading: formMutation.isPending,
  };
}
