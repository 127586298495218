export const getBase64FromFile = async (file: File | Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = `${reader.result}`;
      const cleanBase64 = base64String.replace(/^data:(.*?);base64,/, "");
      resolve(cleanBase64);
    };
    reader.readAsDataURL(file);
  });
};
