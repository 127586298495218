import { UiNoteActionWrapper } from "@/shared/ui";
import { useAutoTranslation } from "@/entities";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Note, notesControllerAddToDeleted } from "@/shared/api/generated.ts";
import { toast } from "react-toastify";
import { sortNewest } from "@/shared/utils";

interface Props {
  id: string;
}

export const NoteDelete: FC<Props> = ({ id }) => {
  const { t } = useAutoTranslation();
  const queryClient = useQueryClient();

  const toTrashMutation = useMutation({
    mutationFn: notesControllerAddToDeleted,
  });

  const handleSubmit = () => {
    toTrashMutation.mutate(id);
    changeInCache();
    toast.info(
      t(
        LANG_KEYS.NOTE_MOVED_TO_DELETED.key,
        LANG_KEYS.NOTE_MOVED_TO_DELETED.value,
      ),
    );
  };

  const changeInCache = () => {
    const notesData = queryClient.getQueryData(QUERY_KEYS.NOTES) as Note[];
    const favoriteData = queryClient.getQueryData(
      QUERY_KEYS.FAVORITE,
    ) as Note[];
    const trashData = queryClient.getQueryData(QUERY_KEYS.DELETED) as Note[];

    let removingNote: boolean | Note = false;

    if (notesData) {
      const updated = JSON.parse(JSON.stringify(notesData)) as Note[];
      const idx = updated.findIndex((item) => item.id == id);
      if (idx != -1) {
        queryClient.setQueryData(QUERY_KEYS.NOTES, () =>
          updated.filter((item) => item.id != id).sort(sortNewest),
        );
        removingNote = updated[idx];
      }
    }

    if (favoriteData) {
      const updated = JSON.parse(JSON.stringify(favoriteData)) as Note[];
      const idx = updated.findIndex((item) => item.id == id);
      if (idx != -1) {
        queryClient.setQueryData(QUERY_KEYS.FAVORITE, () =>
          updated.filter((item) => item.id != id).sort(sortNewest),
        );
        removingNote = updated[idx];
      }
    }

    if (!!removingNote && trashData) {
      const updated = JSON.parse(JSON.stringify(trashData)) as Note[];
      queryClient.setQueryData(QUERY_KEYS.DELETED, () => {
        return [...updated, removingNote].sort(sortNewest);
      });
    }
  };

  return (
    <UiNoteActionWrapper
      onClick={handleSubmit}
      label={t(LANG_KEYS.DELETE_NOTE.key, LANG_KEYS.DELETE_NOTE.value)}
    >
      <img src="/delete-icon.svg" alt="" />
    </UiNoteActionWrapper>
  );
};
