import { IntegrationDataDto } from "@/shared/api/generated.ts";
import { ConnectNotion } from "./connect-notion";
import { DisconnectNotion } from "./disconnect-notion";

export const IntegrationNotion = ({ data }: { data: IntegrationDataDto }) => {
  return (
    <>
      {!data.notionToken && !data.notionToken ? (
        <ConnectNotion />
      ) : (
        <DisconnectNotion />
      )}
    </>
  );
};
