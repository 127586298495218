import { UiNoteActionWrapper } from "@/shared/ui";
import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Note,
  notesControllerAddToFavorite,
  notesControllerRemoveFavorite,
} from "@/shared/api/generated.ts";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";
import { toast } from "react-toastify";
import { sortNewest } from "@/shared/utils";

interface Props {
  id: string;
  isLiked: boolean;
}

export const NoteLike: FC<Props> = ({ id, isLiked }) => {
  const queryClient = useQueryClient();
  const { t } = useAutoTranslation();

  const likeMutation = useMutation({
    mutationFn: notesControllerAddToFavorite,
  });

  const unlikeMutation = useMutation({
    mutationFn: notesControllerRemoveFavorite,
  });

  const handleToggle = () => {
    const toChange = !isLiked;
    const mutation = isLiked ? unlikeMutation : likeMutation;
    changeInCache(toChange);
    mutation.mutate(id);
    if (toChange) {
      toast.success(
        t(LANG_KEYS.ADDED_TO_FAVORITES.key, LANG_KEYS.ADDED_TO_FAVORITES.value),
      );
    } else {
      toast.info(
        t(
          LANG_KEYS.REMOVED_FROM_FAVORITES.key,
          LANG_KEYS.REMOVED_FROM_FAVORITES.value,
        ),
      );
    }
  };

  const changeInCache = (change: boolean) => {
    const favoriteData = queryClient.getQueryData(
      QUERY_KEYS.FAVORITE,
    ) as Note[];
    const notesData = queryClient.getQueryData(QUERY_KEYS.NOTES) as Note[];
    const updatedFavorite = JSON.parse(JSON.stringify(favoriteData)) as Note[];
    if (change && notesData) {
      const updatedNotes = JSON.parse(JSON.stringify(notesData)) as Note[];
      const idx = updatedNotes.findIndex((item) => item.id == id);
      updatedNotes[idx].isFavorite = true;
      queryClient.setQueryData(QUERY_KEYS.NOTES, () =>
        updatedNotes.sort(sortNewest),
      );
      queryClient.setQueryData(QUERY_KEYS.FAVORITE, () => {
        return [...updatedFavorite, updatedNotes[idx]].sort(sortNewest);
      });
    }

    if (!change) {
      const idx = updatedFavorite.findIndex((item) => item.id == id);
      if (notesData) {
        const updatedNotes = JSON.parse(JSON.stringify(notesData)) as Note[];
        updatedNotes[idx].isFavorite = false;
        const newNotes = [...updatedNotes].sort(sortNewest);
        queryClient.setQueryData(QUERY_KEYS.NOTES, () => newNotes);
      }

      queryClient.setQueryData(QUERY_KEYS.FAVORITE, () => {
        return updatedFavorite.filter((item) => item.id != id).sort(sortNewest);
      });
    }
  };

  return (
    <UiNoteActionWrapper
      label={
        !isLiked
          ? t(LANG_KEYS.ADD_TO_FAVORITES.key, LANG_KEYS.ADD_TO_FAVORITES.value)
          : t(
              LANG_KEYS.REMOVE_FROM_FAVORITES.key,
              LANG_KEYS.REMOVE_FROM_FAVORITES.value,
            )
      }
      onClick={handleToggle}
    >
      <img src={!isLiked ? "/like-icon.svg" : "/active-like-icon.svg"} alt="" />
    </UiNoteActionWrapper>
  );
};
