import { UiButton, UiInput } from "@/shared/ui";
import {
  useAutoTranslation,
  useChangeEmailModalStore,
  useUserStore,
} from "@/entities";
import styled from "styled-components";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

export const ChangeEmail = () => {
  const userStore = useUserStore();
  const { t } = useAutoTranslation();
  const changeEmailModalStore = useChangeEmailModalStore();

  return (
    <Wrapper>
      <div className="left">
        <UiInput
          label={t(LANG_KEYS.EMAIL_ADDRESS.key, LANG_KEYS.EMAIL_ADDRESS.value)}
          name="currentEmail"
          value={userStore.email}
          readOnly
        />
      </div>
      <div className="right">
        <UiButton
          type="button"
          variant="white"
          className="h-fit w-full"
          onClick={changeEmailModalStore.showModal}
        >
          <div>
            {capitalize(t(LANG_KEYS.CHANGE.key, LANG_KEYS.CHANGE.value))}
          </div>
        </UiButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  .left {
    flex: 1;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    .left,
    .right {
      flex: 1;
      width: 100%;
    }
  }
`;
