import { UiNoteActionWrapper } from "@/shared/ui";
import { FC } from "react";
import { useAutoTranslation, useChatNoteStore } from "@/entities";
import { LANG_KEYS } from "@/shared/constants";

interface Props {
  id: string;
}

export const NoteChat: FC<Props> = ({ id }) => {
  const { openChat } = useChatNoteStore();
  const { t } = useAutoTranslation();

  const handleOpen = () => {
    openChat({ noteId: id });
  };
  return (
    <UiNoteActionWrapper
      onClick={handleOpen}
      label={t(LANG_KEYS.CHAT_WITH_NOTE.key, LANG_KEYS.CHAT_WITH_NOTE.value)}
    >
      <img src="/chat-icon.svg" alt="" />
    </UiNoteActionWrapper>
  );
};
