import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  hoverEffect?: boolean;
}

export const UiAnchor: FC<Props> = ({ hoverEffect, children }) => {
  return <Wrapper $hoverEffect={!!hoverEffect}>{children}</Wrapper>;
};

const Wrapper = styled.div<{ $hoverEffect: boolean }>`
  width: 100%;
  padding: 10px 16px;
  color: #6b7280;
  font-size: 16px;

  &:hover {
    ${(props) =>
      props.$hoverEffect &&
      `
      text-decoration: underline;
      color: #0056b3;
      background-color: #f9fafb;
      `};
  }
`;
