import { useAutoTranslation } from "@/entities";
import { LANG_KEYS } from "../constants";
import { capitalize } from "./capitalize";

export function useTemplateStrings() {
  const { t } = useAutoTranslation();

  const upToXMinuterPerNote = (minutes: string) => {
    const upTo = t(LANG_KEYS.UP_TO.key, LANG_KEYS.UP_TO.value);
    const minutesPerNote = t(
      LANG_KEYS.MINUTER_PER_NOTE.key,
      LANG_KEYS.MINUTER_PER_NOTE.value,
    );

    return capitalize(`${upTo} ${minutes} ${minutesPerNote}`);
  };

  const xMinutesPerMonth = (minutes: string) => {
    const minutesPerMonth = t(
      LANG_KEYS.MINUTES_PER_MONTH.key,
      LANG_KEYS.MINUTES_PER_MONTH.value,
    );

    return `${minutes} ${minutesPerMonth.toLowerCase()}`;
  };

  const saveUpToXNotes = (notesCount: string) => {
    const saveUpTo = t(LANG_KEYS.SAVE_UP_TO.key, LANG_KEYS.SAVE_UP_TO.value);
    const notes = t(LANG_KEYS.NOTES.key, LANG_KEYS.NOTES.value);

    return capitalize(`${saveUpTo} ${notesCount} ${notes}`);
  };

  const uploadAudioOrVideoFilesUpToXMb = (megabytesCount: string) => {
    const uploadAudioOrVideoFiles = t(
      LANG_KEYS.UPLOAD_AUDIO_OR_VIDEO_FILES.key,
      LANG_KEYS.UPLOAD_AUDIO_OR_VIDEO_FILES.value,
    );

    const upTo = t(LANG_KEYS.UP_TO.key, LANG_KEYS.UP_TO.value);
    const megabytes = t(LANG_KEYS.MEGABYTES.key, LANG_KEYS.MEGABYTES.value);

    return capitalize(
      `${uploadAudioOrVideoFiles} (${upTo} ${megabytesCount} ${megabytes}) `,
    );
  };

  const weSentYouAnActivationLinkToEmailPleaseCheckYourSpamFolder = (
    emailAddress: string,
  ) => {
    const weSentYou = t(
      LANG_KEYS.WE_SENT_YOU_AN_ACTIVATION_LINK_TO_ADDRESS.key,
      LANG_KEYS.WE_SENT_YOU_AN_ACTIVATION_LINK_TO_ADDRESS.value,
    );

    const pleaseCheckYourSpamFolder = t(
      LANG_KEYS.PLEASE_CHECK_YOUR_SPAM_FOLDER.key,
      LANG_KEYS.PLEASE_CHECK_YOUR_SPAM_FOLDER.value,
    );

    return (
      <>
        {capitalize(weSentYou)}
        <span className="underline">&nbsp;{emailAddress + "\n"}</span>
        {capitalize(pleaseCheckYourSpamFolder)}
      </>
    );
  };

  return {
    upToXMinuterPerNote,
    xMinutesPerMonth,
    saveUpToXNotes,
    uploadAudioOrVideoFilesUpToXMb,
    weSentYouAnActivationLinkToEmailPleaseCheckYourSpamFolder,
  };
}
