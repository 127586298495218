import { UiNoteActionWrapper, UiSpinner } from "@/shared/ui";
import { FC, useEffect, useRef, useState } from "react";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

interface Props {
  audioUrl: string;
}

export const NotePlay: FC<Props> = ({ audioUrl }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { t } = useAutoTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCanPlayThrough = () => {
    setIsLoading(false);
    audioRef.current
      ?.play()
      .then(() => setIsPlaying(true))
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  const handleToggle = () => {
    if (isLoading) return;

    if (!audioRef.current) {
      audioRef.current = new Audio(audioUrl);
      audioRef.current.loop = false;
      audioRef.current.addEventListener("canplaythrough", handleCanPlayThrough);
      audioRef.current.addEventListener("ended", handleEnded);
      setIsLoading(true);
    }

    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current
        ?.play()
        .then(() => setIsPlaying(true))
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough,
        );
        audioRef.current.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  return (
    <UiNoteActionWrapper
      onClick={handleToggle}
      label={
        isLoading
          ? t(LANG_KEYS.LOADING.key, LANG_KEYS.LOADING.value)
          : !isPlaying
            ? t(LANG_KEYS.PLAY_AUDIO.key, LANG_KEYS.PLAY_AUDIO.value)
            : t(LANG_KEYS.PAUSE_AUDIO.key, LANG_KEYS.PAUSE_AUDIO.value)
      }
    >
      {isLoading ? (
        <UiSpinner />
      ) : (
        <img src={!isPlaying ? "/play-icon.svg" : "/pause-icon.svg"} alt="" />
      )}
    </UiNoteActionWrapper>
  );
};
