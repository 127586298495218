import {
  forwardRef,
  InputHTMLAttributes,
  PropsWithRef,
  ReactNode,
} from "react";
import styled from "styled-components";
import { UiValidationError } from "./ui-validation-error";
import { StyledTextField } from "./styled";

type Props = {
  name: string;
  label?: string;
  value?: string;
  error?: string;
  type?: "text" | "password" | "number" | "email";
  placeholder?: string;
  wrapperClassname?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
} & PropsWithRef<InputHTMLAttributes<HTMLInputElement>>;

export const UiInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder = "",
      type = "text",
      error,
      name,
      value,
      label,
      wrapperClassname,
      leftIcon,
      rightIcon,
      ...props
    },
    ref,
  ) => {
    return (
      <Wrapper className={wrapperClassname}>
        {!!label && <Label htmlFor={name}>{label}</Label>}
        {!!leftIcon && <LeftIcon $label={!!label}>{leftIcon}</LeftIcon>}
        {!!rightIcon && <RightIcon $label={!!label}>{rightIcon}</RightIcon>}
        <Input
          ref={ref}
          value={value}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          $error={!!error}
          $rightIcon={!!rightIcon}
          $leftIcon={!!leftIcon}
          {...props}
        />
        {!!error && <UiValidationError message={error} />}
      </Wrapper>
    );
  },
);

UiInput.displayName = "UiInput";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
`;

const Input = styled(StyledTextField)<{
  $leftIcon: boolean;
  $rightIcon: boolean;
}>`
  ${(props) =>
    props.$leftIcon &&
    `
      padding-left: 30px;
  `}

  ${(props) =>
    props.$rightIcon &&
    `
      padding-right: 30px;
  `}
`;

const Icon = styled.div<{ $label: boolean }>`
  position: absolute;
  top: ${(props) => (props.$label ? "37px" : "15px")};
`;

const LeftIcon = styled(Icon)`
  left: 10px;
`;
const RightIcon = styled(Icon)`
  right: 10px;
`;
