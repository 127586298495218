import "./spinner.scss";
import { FC } from "react";
import cn from "classnames";

interface Props {
  className?: string;
}

export const UiSpinner: FC<Props> = ({ className }) => {
  return (
    <div className={cn("lds-ring text-[#4E46DC]", className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
