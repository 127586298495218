import { Text, Title, Wrapper } from "../components.tsx";
import { UiButton } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";
import styled from "styled-components";
import { PiMicrophoneSlashFill } from "react-icons/pi";

export const RecordPermissionsDeclined = () => {
  const { t } = useAutoTranslation();
  return (
    <Wrapper>
      <IconWrapper>
        <PiMicrophoneSlashFill size={24} color="#4DA154" />
      </IconWrapper>
      <Title>
        {t(
          LANG_KEYS.CANT_ACCESS_MICRO_TITLE.key,
          LANG_KEYS.CANT_ACCESS_MICRO_TITLE.value,
        )}
      </Title>
      <Text>
        {t(
          LANG_KEYS.CANT_ACCESS_MICRO_TEXT.key,
          LANG_KEYS.CANT_ACCESS_MICRO_TEXT.value,
        )}
      </Text>
      <UiButton
        variant="main"
        className="w-full mt-5"
        onClick={() => {
          window.location.reload();
        }}
      >
        <div>
          {t(LANG_KEYS.RELOAD_THIS_PAGE.key, LANG_KEYS.RELOAD_THIS_PAGE.value)}
        </div>
      </UiButton>
    </Wrapper>
  );
};

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #dcfce7;
`;
