import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { notesControllerGetNotesFromTrash as queryFn } from "@/shared/api/generated.ts";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import {
  useAutoTranslation,
  useEditNoteStore,
  useRecordOptionsStore,
} from "@/entities";
import { UiInput, UiNoteCard, UiSpinner } from "@/shared/ui";
import { FaSearch, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { searchFilterFunc } from "@/shared/utils";
import { motion as m } from "framer-motion";
import { NoteRemove, NoteRestore } from "@/features";

const itemVariants = {
  hidden: { opacity: 0, x: -200 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.05,
      duration: 0.5,
    },
  }),
};

export const NotesDeleted = () => {
  const [q, setQ] = useState("");

  const query = useQuery({
    queryFn,
    queryKey: QUERY_KEYS.DELETED,
  });

  const recordOptionsStore = useRecordOptionsStore();
  const { t } = useAutoTranslation();
  const { openEdit } = useEditNoteStore();

  if (query.isLoading) {
    return (
      <div className="flex justify-center">
        <UiSpinner />
      </div>
    );
  }

  if (query.isSuccess && !query.data.length) {
    return (
      <p className="text-center">
        {t(
          LANG_KEYS.DELETED_NOTES_EMPTY.key,
          LANG_KEYS.DELETED_NOTES_EMPTY.value,
        )}
      </p>
    );
  }

  if (!query.data) {
    return null;
  }

  return (
    <Wrapper>
      <UiInput
        value={q}
        onChange={(e) => setQ(e.target.value)}
        leftIcon={
          <button className="cursor-default" type="button">
            <FaSearch color="gray" />
          </button>
        }
        rightIcon={
          q ? (
            <button onClick={() => setQ("")} type="button">
              <FaTimes color="gray" />
            </button>
          ) : undefined
        }
        label={t(LANG_KEYS.QUICK_SEARCH.key, LANG_KEYS.QUICK_SEARCH.value)}
        name="trash-search"
      />
      <List>
        {query.data
          .filter((item) => searchFilterFunc(item, q))
          .map((item, i) => (
            <m.div
              custom={i}
              initial="hidden"
              animate="visible"
              variants={itemVariants}
              key={`trash-note-${i}`}
            >
              <Item>
                <UiNoteCard
                  {...item}
                  actionsGap={5}
                  actionItemFlexFull
                  summaryView={recordOptionsStore.summaryView}
                  onExpand={() => {
                    openEdit({ note: item });
                  }}
                  actions={[
                    <NoteRestore id={item.id} />,
                    <NoteRemove id={item.id} />,
                  ]}
                />
              </Item>
            </m.div>
          ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const List = styled.ul`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    flex-wrap: unset;
  }
`;

const Item = styled.li`
  width: 350px;
  height: 342px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
