import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Note } from "@/shared/api/generated.ts";

type OpenEditPayload = {
  note: Note;
};

type Store = {
  data: Note | null;
  openEdit: (payload: OpenEditPayload) => void;
  closeEdit: () => void;
};

export const useEditNoteStore = create<Store>()(
  devtools(
    (set) => ({
      data: null,
      openEdit: (payload: OpenEditPayload) => {
        set(() => ({
          data: payload.note,
        }));
      },
      closeEdit: () => {
        // BodyNoScroll.Disable();
        set(() => ({
          data: null,
        }));
      },
    }),
    { name: "EditNoteStore" },
  ),
);
