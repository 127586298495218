import styled from "styled-components";
import { UiPricingPlanCard, UiSmallTabs } from "@/shared/ui";
import { LANG_KEYS, PLANS_DATA, ROUTER_PATHS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";
import { useState } from "react";
import { useAutoTranslation, useUserStore } from "@/entities";
import { handleCheckout } from "@/features";
import { useNavigate } from "react-router-dom";
import { NewCheckoutSessionDtoReqPlan } from "@/shared/api/generated.ts";

export const PricingSubscriptionCards = () => {
  const { t } = useAutoTranslation();
  const { plan, email } = useUserStore();
  const navigate = useNavigate();

  const [activeTabIdx, setActiveTabIdx] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleToggleTabs = (idx: number, _: string) => {
    setActiveTabIdx(idx);
  };

  const tabsTitles = [
    t(LANG_KEYS.MONTHLY.key, LANG_KEYS.MONTHLY.value),
    t(LANG_KEYS.ANNUALLY.key, LANG_KEYS.ANNUALLY.value),
  ];

  const onCheckout = async (checkoutPlan: NewCheckoutSessionDtoReqPlan) => {
    if (plan == checkoutPlan) return;

    if (!email) {
      navigate(ROUTER_PATHS.AUTH_SIGN_UP);
      return;
    }

    await handleCheckout(checkoutPlan, activeTabIdx == 0 ? "MONTH" : "YEAR");
  };

  return (
    <>
      <TabsWrapper>
        <UiSmallTabs
          titles={tabsTitles}
          activeIdx={activeTabIdx}
          onToggle={handleToggleTabs}
        />
      </TabsWrapper>
      <Wrapper>
        <Item>
          <UiPricingPlanCard
            title={capitalize(t(LANG_KEYS.FREE.key, LANG_KEYS.FREE.value))}
            description={t(
              LANG_KEYS.TRY_FOR_FREE_GET_STARTED_TODAY.key,
              LANG_KEYS.TRY_FOR_FREE_GET_STARTED_TODAY.value,
            )}
            isCurrent={plan == PLANS_DATA.FREE.name}
            optionList="FREE"
          />
        </Item>
        <Item>
          <UiPricingPlanCard
            title={capitalize(
              t(LANG_KEYS.ADVANCED.key, LANG_KEYS.ADVANCED.value),
            )}
            description={t(
              LANG_KEYS.PERFECT_FOR_FOCUSED_NOTES_AND_MEETINGS.key,
              LANG_KEYS.PERFECT_FOR_FOCUSED_NOTES_AND_MEETINGS.value,
            )}
            price={activeTabIdx == 0 ? 9 : 81}
            onCheckout={() => onCheckout("ADVANCED")}
            isYearly={activeTabIdx == 1}
            isPopular
            isCurrent={plan == PLANS_DATA.ADVANCED.name}
            optionList="ADVANCED"
          />
        </Item>
        <Item>
          <UiPricingPlanCard
            onCheckout={() => onCheckout("PRO")}
            title={capitalize(t(LANG_KEYS.PRO.key, LANG_KEYS.PRO.value))}
            description={t(
              LANG_KEYS.UNLIMITED_NOTES_ADVANCED_FEATURES_SERIOUS_PRODUCTIVITY
                .key,
              LANG_KEYS.UNLIMITED_NOTES_ADVANCED_FEATURES_SERIOUS_PRODUCTIVITY
                .value,
            )}
            price={activeTabIdx == 0 ? 24 : 216}
            isYearly={activeTabIdx == 1}
            isCurrent={plan == PLANS_DATA.PRO.name}
            optionList="PRO"
          />
        </Item>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 32px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  flex: 1;
`;

const TabsWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;
