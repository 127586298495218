import { UiLogo } from "@/shared/ui/ui-logo";
import { UiSpinner } from "@/shared/ui/ui-spinner";
import { FC } from "react";

interface Props {
  fullScreen?: boolean;
}

export const UiLoading: FC<Props> = ({ fullScreen }) => {
  if (fullScreen) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-3 text-[#4E46DC]">
          <UiLogo hoverEffect={false} />
          <UiSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-3 text-[#4E46DC]">
      <UiLogo hoverEffect={false} />
      <UiSpinner />
    </div>
  );
};
