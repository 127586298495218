import styles from "./styles.module.scss";
import { UiPaper } from "@/shared/ui";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";
import { EditRecordOptionsForm } from "./edit-form/ui";
import { FC } from "react";
import styled from "styled-components";
import { useAutoTranslation } from "@/entities";

interface Props {
  onClose: () => void;
}

export const RecordOptionsModalCard: FC<Props> = ({ onClose }) => {
  const { t } = useAutoTranslation();
  return (
    <UiPaper className={styles.card} onCrossClick={onClose}>
      <ModalDescrWrapper>
        <IconWrapper>
          <img src="/settings.svg" alt="" />
        </IconWrapper>
        <ModalDescrTitle>
          AudioNotes.ai&nbsp;
          {capitalize(t(LANG_KEYS.SETTINGS.key, LANG_KEYS.SETTINGS.value))}
        </ModalDescrTitle>
        <ModalDescrText>
          {t(
            LANG_KEYS
              .CUSTOMIZE_YOUR_NOTE_TAKING_EXPERIENCE_BY_ADJUSTING_THE_APP_SETTINGS_TO_YOUR_PREFERENCE
              .key,
            LANG_KEYS
              .CUSTOMIZE_YOUR_NOTE_TAKING_EXPERIENCE_BY_ADJUSTING_THE_APP_SETTINGS_TO_YOUR_PREFERENCE
              .value,
          )}
        </ModalDescrText>
      </ModalDescrWrapper>
      <EditRecordOptionsForm onFinish={onClose} />
    </UiPaper>
  );
};

const ModalDescrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: rgb(220, 252, 231);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalDescrTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;

const ModalDescrText = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #111827;
  text-align: center;
`;
