import moment from "moment";

export const formatTime = (totalSeconds: number) => {
  const duration = moment.duration(totalSeconds, "seconds");

  if (duration.asHours() >= 1) {
    return moment.utc(totalSeconds * 1000).format("H:mm:ss");
  } else {
    return moment.utc(totalSeconds * 1000).format("m:ss");
  }
};
