import { useRecordOptionsStore } from "@/entities";
import {
  AddCustomSummaryStyleDtoReq,
  EditCustomSumaryStyleDtoReq,
  userControllerEditCustomSummaryStyle,
} from "@/shared/api/generated";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

type FormData = AddCustomSummaryStyleDtoReq;
export function useEditCustomPromptForm(
  defaultValues: EditCustomSumaryStyleDtoReq,
  cb?: (title: string, text: string) => void,
) {
  const recordOptionsStore = useRecordOptionsStore();

  const formMutation = useMutation({
    mutationFn: userControllerEditCustomSummaryStyle,
    async onSuccess(res) {
      recordOptionsStore.setSummaryStyle(res);
      if (cb) {
        const obj = res.find((item) => item.id == defaultValues.id);

        if (!obj) return;

        cb(obj.title, obj.text);
      }
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      text: defaultValues.text,
      title: defaultValues.title,
    },
  });

  useEffect(() => {
    setValue("title", defaultValues.title);
    setValue("text", defaultValues.text);
  }, [defaultValues]);

  return {
    register,
    errors,
    isLoading: formMutation.isPending,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate({
        ...values,
        id: defaultValues.id,
      });
    }),
    reset,
  };
}
