import { FC, useEffect, useRef, useState } from "react";
import styled, { StyleSheetManager } from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import isPropValid from "@emotion/is-prop-valid";
import { UiLink } from "@/shared/ui/ui-link";
import { SelectOption } from "@/shared/types";
import { v4 } from "uuid";

interface Props {
  options: SelectOption[];
  defaultValue?: string;
  onSelect?: (option: SelectOption) => void;
}

export const UiDropdown: FC<Props> = ({ options, defaultValue, onSelect }) => {
  const [uniqueKey] = useState<string>(v4());
  const [isOpen, setOpen] = useState(false);

  const [currentIdx, setCurrentIdx] = useState<number>(0);

  const wrapperRef = useRef<any>();

  const toggle = () => setOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !wrapperRef.current ||
      wrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleSelect = (value: SelectOption, i: number) => {
    setOpen(false);
    setCurrentIdx(i);
    if (onSelect) {
      onSelect(value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const idx = options.findIndex((item) => item.value === defaultValue);
      if (idx >= 0) {
        setCurrentIdx(idx);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [defaultValue, options]);

  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <Wrapper ref={wrapperRef}>
        <Label onClick={toggle}>
          <UiLink>
            <div className="capitalize">{options[currentIdx].name}</div>
          </UiLink>
          <Arrow isOpen={isOpen}>
            <FaChevronDown color="#9ca3af" size={12} />
          </Arrow>
        </Label>

        <List isOpen={isOpen}>
          {options.map((item, i) => (
            <ListItem
              onClick={() => handleSelect(item, i)}
              key={`select-${uniqueKey}-item-${i}`}
            >
              <UiLink>
                <div className="capitalize">{item.name}</div>
              </UiLink>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </StyleSheetManager>
  );
};

interface ArrowProps {
  isOpen: boolean;
}
interface ListProps {
  isOpen: boolean;
}

const List = styled.ul<ListProps>`
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.176) 0 16px 48px 0;
  background-color: #fff;
  border-radius: 12px;
  top: calc(100% + 20px);
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid white;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
  min-width: 225px;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Arrow = styled.div<ArrowProps>`
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const Wrapper = styled.div`
  position: relative;
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ListItem = styled.li`
  padding: 8px 16px;

  &:hover {
    background-color: #f9fafb;
  }
`;
