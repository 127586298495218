import { userControllerProvideNewEmail as mutationFn } from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

type FormData = { email: string };
export function useProvideNewEmailForm(code: string, handleRes: () => void) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess() {
      handleRes();
      reset();
    },
  });

  return {
    register,
    handleSubmit: handleSubmit(({ email }) => {
      formMutation.mutate({ email, code });
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
