import { createRoot } from "react-dom/client";
import App from "./app/app.tsx";
import { Suspense } from "react";
import { UiLoading } from "@/shared/ui";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};
createRoot(document.getElementById("root")!).render(
  <Suspense fallback={<UiLoading fullScreen />}>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={options}
    />
    <App />
  </Suspense>,
);
