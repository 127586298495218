export const TRANSCRIBE_SOCKET_COMMANDS = {
  //ERRORS
  USER_ID_NOT_FOUND: "User id not found",
  UNSUPPORTED_FORMAT: "Unsupported file format",
  UNDETECTED_LANGUAGE: "Undetected language",
  LIMIT_10_CURRENT_NOTES: "Limit 10 current notes",
  LIMIT_FILE_SIZE_5_MB_FREE_PLAN: "Limit file size 5MB for free plan",
  PROGRESS_PERCENT: "ProgressPercent",

  //STEP
  DONE_INSPECTING_FILE: "Done Inspecting File",
  DONE_WAV_CONVERTING: "Done Converting to wav format",
  DONE_LANG_DETECTION: "Done Language detection",
  DONE_ORIGINAL_TEXT: "Done Transcribe original text",
  DONE_PARAGRAPH_FORM: "Done Paragraph Form",
  DONE_BULLET_POINTS: "Done Bullet Points",
  DONE_TITLE: "Done Title",
  DONE_FILE: "Done File",
  CHUNK_TRANSCRIBING: "Chunk Transcribing",
  SAVED: "SAVED",
  NEW_PROCESS_ID: "NewProcessId",
  //MESSAGES
  CONNECTED: "CONNECTED",
};

export const TRANSCRIBE_SOCKET_ERRORS = [
  TRANSCRIBE_SOCKET_COMMANDS.USER_ID_NOT_FOUND,
  TRANSCRIBE_SOCKET_COMMANDS.UNSUPPORTED_FORMAT,
  TRANSCRIBE_SOCKET_COMMANDS.UNDETECTED_LANGUAGE,
  TRANSCRIBE_SOCKET_COMMANDS.LIMIT_10_CURRENT_NOTES,
  TRANSCRIBE_SOCKET_COMMANDS.LIMIT_FILE_SIZE_5_MB_FREE_PLAN,
];

export const CHAT_SOCKET_COMMANDS = {
  //STEP
  CHAT_RESPONSE: "CHAT_RESPONSE",

  //MESSAGES
  CONNECTED: "CONNECTED",
};
