import {
  useAutoTranslation,
  useRecordProcessStore,
  useTextNoteStore,
} from "@/entities";
import { AnimatePresence, motion as m } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";
import { UiButton, UiSocketStep } from "@/shared/ui";
import styled from "styled-components";
import { useTranscribeProcess } from "./model";
import { useTextProcess } from "./text-model";
import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { LANG_KEYS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";

export const TranscribeProcess = () => {
  const { transcribing } = useRecordProcessStore();
  const { original } = useTextNoteStore();
  const { t } = useAutoTranslation();

  const {
    steps,
    currentIdx,
    percent: transcribePercent,
  } = useTranscribeProcess();

  const {
    steps: textSteps,
    currentIdx: textCurrentIdx,
    percent: textPercent,
  } = useTextProcess();

  const [isShowDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (!transcribing) {
      setShowDetails(false);
    }
  }, [transcribing]);

  useEffect(() => {
    if (!original) {
      setShowDetails(false);
    }
  }, [original]);

  return (
    <>
      <AnimatePresence>
        {transcribing && (
          <m.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.45, ease: "easeOut" }}
            exit={{ scale: 0 }}
          >
            <Wrapper>
              <div className="flex flex-col gap-2 items-center justify-center relative">
                <Percents>{transcribePercent.toFixed(2)}%</Percents>
                <ThreeDots color="#fff" />
                <UiButton
                  className={styles.detailsBtn}
                  variant="white"
                  onClick={() => setShowDetails((prev) => !prev)}
                >
                  <div>
                    {isShowDetails
                      ? capitalize(t(LANG_KEYS.HIDE.key, LANG_KEYS.HIDE.value))
                      : capitalize(
                          t(LANG_KEYS.SHOW.key, LANG_KEYS.SHOW.value),
                        )}{" "}
                    {t(
                      LANG_KEYS.DETAILS.key,
                      LANG_KEYS.DETAILS.value,
                    ).toLowerCase()}
                  </div>
                </UiButton>
              </div>
              <StepsWrapper $open={isShowDetails}>
                {steps.map((item, idx) => {
                  const title = t(item.data.key, item.data.value);
                  return (
                    <div key={`step-socket-${idx}`}>
                      <UiSocketStep
                        text={item.text}
                        title={title}
                        done={item.done}
                        current={idx == currentIdx}
                      />
                    </div>
                  );
                })}
              </StepsWrapper>
            </Wrapper>
          </m.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!!original && (
          <m.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.45, ease: "easeOut" }}
            exit={{ scale: 0 }}
          >
            <Wrapper>
              <div className="flex flex-col gap-2 items-center justify-center relative">
                <Percents>{textPercent.toFixed(2)}%</Percents>
                <ThreeDots color="#fff" />
                <UiButton
                  className={styles.detailsBtn}
                  variant="white"
                  onClick={() => setShowDetails((prev) => !prev)}
                >
                  <div>
                    {isShowDetails
                      ? capitalize(t(LANG_KEYS.HIDE.key, LANG_KEYS.HIDE.value))
                      : capitalize(
                          t(LANG_KEYS.SHOW.key, LANG_KEYS.SHOW.value),
                        )}{" "}
                    {t(
                      LANG_KEYS.DETAILS.key,
                      LANG_KEYS.DETAILS.value,
                    ).toLowerCase()}
                  </div>
                </UiButton>
              </div>
              <StepsWrapper $open={isShowDetails}>
                {textSteps.map((item, idx) => {
                  const title = t(item.data.key, item.data.value);
                  return (
                    <div key={`step-text-socket-${idx}`}>
                      <UiSocketStep
                        text={item.text}
                        title={title}
                        done={item.done}
                        current={idx == textCurrentIdx}
                      />
                    </div>
                  );
                })}
              </StepsWrapper>
            </Wrapper>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Wrapper = styled.div`
  background-color: var(--main-purple);
  padding: 20px;
  border-radius: 20px;
  width: 600px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Percents = styled.div`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;
`;

const StepsWrapper = styled.div<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }

  overflow: ${({ $open }) => ($open ? "auto" : "hidden")};
  transition: all 0.3s ease;
  max-height: ${(props) => (props.$open ? "500px" : "0")};
`;
