import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  className?: string;
}

export const UiHeading: FC<Props> = ({ children, className }) => {
  return <Heading className={className}>{children}</Heading>;
};

const Heading = styled.h2`
  font-size: 40px;
  font-weight: 600;
  color: rgb(33, 37, 41);
  text-align: center;
  margin-bottom: 20px;
`;
