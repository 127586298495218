import {
  useChangeEmailModalStore,
  useChatNoteStore,
  useDeleteAccountModalStore,
  useEditNoteStore,
  useRecordOptionsModalStore,
  useSearchNotesStore,
  useSuggestionModalStore,
  useTextNoteStore,
  useUtilityStore,
} from "@/entities";
import { AnimatePresence, motion as m } from "framer-motion";
import { UiBackdrop, UiPaper } from "@/shared/ui";
import {
  ChangeProcess,
  ChatNoteForm,
  DeleteAccount,
  EditNoteForm,
  NoteLimitReached,
  RecordOptionsModalCard,
  RecordPermissionsDeclined,
  SearchNote,
  SuggestionForm,
  TextNoteForm,
} from "@/features";
import styles from "./styles.module.scss";

export const ModalsWidget = () => {
  const recordOptionsModalStore = useRecordOptionsModalStore();
  const searchNotesStore = useSearchNotesStore();
  const editNoteStore = useEditNoteStore();
  const chatNoteStore = useChatNoteStore();
  const textNoteStore = useTextNoteStore();
  const suggestionModalStore = useSuggestionModalStore();
  const deleteAccountModalStore = useDeleteAccountModalStore();
  const changeEmailModalStore = useChangeEmailModalStore();
  const utilityStore = useUtilityStore();

  return (
    <AnimatePresence mode="wait">
      {recordOptionsModalStore.isOpen && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
          onClick={recordOptionsModalStore.closeModal}
        >
          <UiBackdrop handleClose={recordOptionsModalStore.closeModal}>
            <RecordOptionsModalCard
              onClose={recordOptionsModalStore.closeModal}
            />
          </UiBackdrop>
        </m.div>
      )}

      {searchNotesStore.isOpen && (
        <m.div
          initial={{ opacity: 0, y: -15 }}
          exit={{
            y: -20,
            opacity: 0,
            filter: "blur(5px)",
            transition: { ease: "easeIn", duration: 0.22 },
          }}
          animate={{
            opacity: 1,
            y: 0,
            filter: "blur(0px)",
            transition: { type: "spring", duration: 0.7 },
          }}
          className="fixed top-0 left-0 w-screen h-screen z-[999] backdrop-blur bg-slate-900/25"
        >
          <SearchNote />
        </m.div>
      )}

      {!!editNoteStore.data && (
        <m.div
          initial={{ opacity: 0, y: -15 }}
          exit={{
            y: -20,
            opacity: 0,
            filter: "blur(5px)",
            transition: { ease: "easeIn", duration: 0.22 },
          }}
          animate={{
            opacity: 1,
            y: 0,
            filter: "blur(0px)",
            transition: { type: "spring", duration: 0.7 },
          }}
          onClick={editNoteStore.closeEdit}
          className="fixed top-0 left-0 w-screen h-screen z-[999] backdrop-blur bg-slate-900/25 flex justify-center items-center"
        >
          <EditNoteForm data={editNoteStore.data} />
        </m.div>
      )}

      {!!chatNoteStore.noteId && (
        <m.div
          initial={{ opacity: 0, y: -15 }}
          exit={{
            y: -20,
            opacity: 0,
            filter: "blur(5px)",
            transition: { ease: "easeIn", duration: 0.22 },
          }}
          animate={{
            opacity: 1,
            y: 0,
            filter: "blur(0px)",
            transition: { type: "spring", duration: 0.7 },
          }}
          onClick={chatNoteStore.closeChat}
          className="fixed top-0 left-0 w-screen h-screen z-[999] backdrop-blur bg-slate-900/25 flex justify-center items-center"
        >
          <ChatNoteForm noteId={chatNoteStore.noteId} />
        </m.div>
      )}

      {textNoteStore.open && (
        <m.div
          initial={{ opacity: 0, y: -15 }}
          exit={{
            y: -20,
            opacity: 0,
            filter: "blur(5px)",
            transition: { ease: "easeIn", duration: 0.22 },
          }}
          animate={{
            opacity: 1,
            y: 0,
            filter: "blur(0px)",
            transition: { type: "spring", duration: 0.7 },
          }}
          onClick={textNoteStore.closeTextNote}
          className="fixed top-0 left-0 w-screen h-screen z-[999] backdrop-blur bg-slate-900/25 flex justify-center items-center"
        >
          <TextNoteForm />
        </m.div>
      )}

      {suggestionModalStore.isOpen && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
          onClick={suggestionModalStore.closeModal}
        >
          <UiBackdrop handleClose={suggestionModalStore.closeModal}>
            <SuggestionForm />
          </UiBackdrop>
        </m.div>
      )}

      {deleteAccountModalStore.isOpen && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
          onClick={deleteAccountModalStore.closeModal}
        >
          <UiBackdrop handleClose={deleteAccountModalStore.closeModal}>
            <DeleteAccount />
          </UiBackdrop>
        </m.div>
      )}

      {changeEmailModalStore.isOpen && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
          onClick={changeEmailModalStore.closeModal}
        >
          <UiBackdrop handleClose={changeEmailModalStore.closeModal}>
            <UiPaper className={styles.confirmChangeEmailCard}>
              <ChangeProcess onClose={changeEmailModalStore.closeModal} />
            </UiPaper>
          </UiBackdrop>
        </m.div>
      )}

      {utilityStore.isNoteLimitedAlert && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
          onClick={changeEmailModalStore.closeModal}
        >
          <UiBackdrop
            handleClose={() =>
              utilityStore.setNoteLimitedAlert({ isNoteLimitedAlert: false })
            }
          >
            <UiPaper className={styles.infoModalCard}>
              <NoteLimitReached />
            </UiPaper>
          </UiBackdrop>
        </m.div>
      )}

      {utilityStore.isRecordPermissionDeclined && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="fixed w-screen h-screen top-0 left-0 z-50"
        >
          <UiBackdrop>
            <UiPaper className={styles.infoModalCard}>
              <RecordPermissionsDeclined />
            </UiPaper>
          </UiBackdrop>
        </m.div>
      )}
    </AnimatePresence>
  );
};
