import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { UiLink } from "@/shared/ui";
import { motion as m } from "framer-motion";

interface Props {
  title: string;
  heading: string;
  text: string;
  href: string;
  children: ReactNode;
}

export const AuthWidget: FC<Props> = ({
  children,
  href,
  text,
  heading,
  title,
}) => {
  const location = useLocation();
  return (
    <Wrapper>
      <m.main
        key={location.pathname}
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.25, ease: "linear" }}
      >
        <Heading>
          <h3>{title}</h3>
          <HeadingContent>
            <h4>{heading}</h4>
            <Link to={href}>
              <UiLink variant="purple">{text}</UiLink>
            </Link>
          </HeadingContent>
        </Heading>

        <FormContent>{children}</FormContent>
      </m.main>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Heading = styled.div`
  margin-top: 32px;

  h3 {
    white-space: nowrap;
    font-weight: 700;
    font-size: 24px;
  }
`;

const HeadingContent = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  h4 {
    font-size: 14px;
    color: var(--gray500);
  }
`;

const FormContent = styled.div`
  margin-top: 40px;
`;
