import { useEffect } from "react";
import { UiLoading } from "@/shared/ui";
import { useNavigate, useParams } from "react-router-dom";
import { authControllerEmailConfirm } from "@/shared/api/generated.ts";
import {
  useAutoTranslation,
  useRecordOptionsStore,
  useSuggestionModalStore,
  useUserStore,
} from "@/entities";
import { LANG_KEYS, QUERY_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ToastSuccessConfirmedEmail = () => {
  const suggestionModalStore = useSuggestionModalStore();
  const { t } = useAutoTranslation();
  return (
    <div>
      {t(
        LANG_KEYS.EMAIL_CONFIRMED_TOAST_TEXT.key,
        LANG_KEYS.EMAIL_CONFIRMED_TOAST_TEXT.value,
      )}{" "}
      <span
        onClick={suggestionModalStore.showModal}
        className="underline hover:cursor-pointer"
      >
        {t(LANG_KEYS.THROUGH_THIS_LINK.key, LANG_KEYS.THROUGH_THIS_LINK.value)}.
      </span>
    </div>
  );
};

export const ConfirmEmailPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { login } = useUserStore();
  // const [email, setEmail] = useState<string>("");
  // const { t } = useAutoTranslation();
  const { invalidateOptions } = useRecordOptionsStore();

  const queryClient = useQueryClient();

  useEffect(() => {
    const confirmEmail = async () => {
      if (!code) {
        navigate(ROUTER_PATHS.HOME, { replace: true });
        return;
      }

      try {
        const { access_token } = await authControllerEmailConfirm(code);
        // const { email } = decodeJwt(access_token);
        login(access_token);
        toast.success(<ToastSuccessConfirmedEmail />, {
          autoClose: false,
        });
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
        await invalidateOptions();
        // setEmail(email);
      } catch (e) {
        console.log(e);
      }
      navigate(ROUTER_PATHS.HOME, { replace: true });
    };

    confirmEmail();
  }, [code]);

  return <UiLoading fullScreen={true} />;

  // return (
  //   <UiShow>
  //     <UiShow.When isTrue={!email}>
  //       <UiLoading fullScreen />
  //     </UiShow.When>
  //     <UiShow.When isTrue={!!code && !!email}>
  //       <Wrapper>
  //         <Link to={ROUTER_PATHS.HOME}>
  //           <div className="flex justify-center">
  //             <UiLogo hoverEffect />
  //           </div>
  //         </Link>
  //         <div className="flex flex-col gap-2 items-center">
  //           <IoShieldCheckmark color="#22c55e" size={50} />
  //           <h2 className="text-center text-2xl">
  //             {t(
  //               LANG_KEYS.EMAIL_CONFIRMED.key,
  //               LANG_KEYS.EMAIL_CONFIRMED.value,
  //             )}
  //             <br />
  //             {email}
  //           </h2>
  //         </div>
  //         <Link to={ROUTER_PATHS.HOME}>
  //           <UiButton variant="main" className="w-full">
  //             <div>{t(LANG_KEYS.MAIN_PAGE.key, LANG_KEYS.MAIN_PAGE.value)}</div>
  //           </UiButton>
  //         </Link>
  //       </Wrapper>
  //     </UiShow.When>
  //   </UiShow>
  // );
};

// const Wrapper = styled.form`
//   min-width: 400px;
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
//   align-items: center;
//
//   @media screen and (max-width: 425px) {
//     min-width: 90%;
//   }
// `;
