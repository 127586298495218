import styled from "styled-components";
import { FC } from "react";

interface Props {
  message: string;
}

export const UiValidationError: FC<Props> = ({ message }) => {
  return <Message>{message}</Message>;
};

const Message = styled.div`
  color: red;
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  right: 0;
  font-family: "Georgia", sans-serif;
  letter-spacing: 1px;
`;
