import { useForm } from "react-hook-form";
import {
  authControllerResetPassword as mutationFn,
  ResetPasswordDtoReq,
} from "@/shared/api/generated.ts";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

type FormData = ResetPasswordDtoReq;
export function useRequestResetPassForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { t } = useAutoTranslation();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess() {
      toast.success(
        t(
          LANG_KEYS.CHECK_YOUR_EMAIL_TO_UPDATE_YOUR_PASSWORD.key,
          LANG_KEYS.CHECK_YOUR_EMAIL_TO_UPDATE_YOUR_PASSWORD.value,
        ),
      );
      reset();
    },
  });

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
