import {
  ProvideCodeDtoReq,
  userControllerProvideStartChangeEmailCode as mutationFn,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

type FormData = ProvideCodeDtoReq;

export function useChangeEmailFirstCodeConfForm(
  handleRes: (isRight: boolean, code: string) => void,
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<FormData>();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess({ isRight }) {
      const sentCode = getValues().code;
      handleRes(isRight, sentCode);
      if (isRight) {
        reset();
      }
    },
  });

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
