import { Note } from "@/shared/api/generated.ts";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CgClose } from "react-icons/cg";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation, useEditNoteStore } from "@/entities";
import { UiButton } from "@/shared/ui";
import { useEditNoteForm } from "./model.ts";
import { capitalize } from "@/shared/utils";

interface Props {
  note: Note;
}

type CurrentField = "list" | "original" | "paragraph";

enum Mode {
  NOTES,
  ORIGINAL,
  PARAGRAPH,
}

export const EditableForm: FC<Props> = ({ note }) => {
  const { closeEdit } = useEditNoteStore();
  const { handleSubmit, setValue, register, isLoading, watch } =
    useEditNoteForm(note);
  const [mode, setMode] = useState<Mode>(Mode.PARAGRAPH);
  // const [text, setText] = useState(getValues("paragraph"));
  const [currentField, setCurrentField] = useState<CurrentField>("paragraph");

  const { t } = useAutoTranslation();

  const values = watch();

  useEffect(() => {
    let field: CurrentField | undefined;
    switch (mode) {
      case Mode.NOTES:
        field = "list";
        break;
      case Mode.PARAGRAPH:
        field = "paragraph";
        break;
      case Mode.ORIGINAL:
        field = "original";
        break;
    }
    if (field) {
      setCurrentField(field);
    }
  }, [mode]);

  const handleContentInput = (e: any) => {
    const value = e.target.value;
    setValue(currentField, value);
  };

  const handleKeyboardPress = (e: any) => {
    if (isLoading) return;
    if (e.keyCode == 27 || e.key == "Escape") {
      closeEdit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPress);
    // BodyNoScroll.Enable();
    // window.scrollTo({ top: 0, behavior: "instant" });
    return () => {
      window.removeEventListener("keydown", handleKeyboardPress);
    };
  }, []);

  const handleToggleNotes = () => {
    if (mode == Mode.NOTES) {
      setMode(Mode.PARAGRAPH);
    } else {
      setMode(Mode.NOTES);
    }
  };

  const handleToggleMode = () => {
    if (mode == Mode.ORIGINAL || mode == Mode.NOTES) {
      setMode(Mode.PARAGRAPH);
    } else {
      setMode(Mode.ORIGINAL);
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit} className="shadow-sm">
      <div className="hidden">
        <input type="text" {...register("original", { required: false })} />
        <input type="text" {...register("paragraph", { required: false })} />
        <input type="text" {...register("list", { required: false })} />
      </div>
      <Top>
        <TitleTextarea
          value={values["title"]}
          {...register("title", {
            required: false,
          })}
        />
        <CloseBtn type="button" onClick={closeEdit}>
          <CgClose color="#000" size={20} />
        </CloseBtn>
      </Top>

      <Content>
        <ContentTextarea
          onChange={handleContentInput}
          value={values[currentField] || ""}
        />
      </Content>

      <Footer>
        <div className="left">
          <ToggleNoteBtn type="button" onClick={handleToggleNotes}>
            <img
              src={
                mode != Mode.NOTES ? "/list-icon.svg" : "/paragraph-icon.svg"
              }
              alt=""
            />
          </ToggleNoteBtn>
          <ToggleModeBtn type="button" onClick={handleToggleMode}>
            {mode == Mode.ORIGINAL
              ? t(LANG_KEYS.VIEW_SUMMARY.key, LANG_KEYS.VIEW_SUMMARY.value)
              : t(
                  LANG_KEYS.VIEW_ORIGINAL_TRANSCRIPT.key,
                  LANG_KEYS.VIEW_ORIGINAL_TRANSCRIPT.value,
                )}
          </ToggleModeBtn>
        </div>
        <div>
          <UiButton type="submit" variant="main">
            <div>{capitalize(t(LANG_KEYS.SAVE.key, LANG_KEYS.SAVE.value))}</div>
          </UiButton>
        </div>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  background-color: #fff;
  border-radius: 22px;
  border: 1px solid rgb(222, 226, 230);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 30px;
`;

const TitleTextarea = styled.textarea`
  padding: 3px 10px;
  width: 100%;
  resize: none;
  height: 30px;
  font-weight: 700;
  //border: 1px solid rgba(79, 70, 229, 0.25);
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  outline: none;
`;
const ContentTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 3px 10px;
  resize: none;
  //border: 1px solid rgba(79, 70, 229, 0.25);
  outline: none;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 9px;
  right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  padding: 0 20px 10px;
`;

const Footer = styled.div`
  padding: 8px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(229, 231, 235);

  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const ToggleNoteBtn = styled.button`
  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
`;

const ToggleModeBtn = styled.button`
  color: rgb(107, 114, 128);
  font-size: 14px;
  font-style: italic;
  text-align: left;

  &:hover {
    color: #4b5563;
  }
`;
