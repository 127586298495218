import { useSuggestionForm } from "./model";
import styles from "./styles.module.scss";
import { UiButton, UiInput, UiPaper, UiTextarea } from "@/shared/ui";
import { useAutoTranslation, useSuggestionModalStore } from "@/entities";
import styled from "styled-components";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

export const SuggestionForm = () => {
  const { isLoading, register, errors, handleSubmit } = useSuggestionForm();
  const suggestionModalStore = useSuggestionModalStore();
  const { t } = useAutoTranslation();

  return (
    <UiPaper
      className={styles.suggestionCard}
      isLoading={isLoading}
      onCrossClick={suggestionModalStore.closeModal}
    >
      <Form onSubmit={handleSubmit}>
        <Title>
          {capitalize(
            t(
              LANG_KEYS.HELP_US_ENHANCE_THE_AUDIONOTES_EXPERIENCE.key,
              LANG_KEYS.HELP_US_ENHANCE_THE_AUDIONOTES_EXPERIENCE.value,
            ),
          )}
        </Title>

        <UiInput
          wrapperClassname="w-full"
          label={capitalize(
            t(LANG_KEYS.YOUR_NAME.key, LANG_KEYS.YOUR_NAME.value),
          )}
          placeholder={capitalize(
            t(LANG_KEYS.OPTIONAL.key, LANG_KEYS.OPTIONAL.value),
          )}
          {...register("name", { required: false })}
        />
        <UiInput
          wrapperClassname="w-full"
          error={errors?.email?.message}
          label={capitalize(
            t(LANG_KEYS.YOUR_EMAIL.key, LANG_KEYS.YOUR_EMAIL.value),
          )}
          placeholder={capitalize(
            t(LANG_KEYS.OPTIONAL.key, LANG_KEYS.OPTIONAL.value),
          )}
          {...register("email", {
            required: false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t(
                LANG_KEYS.INVALID_EMAIL_FORMAT.key,
                LANG_KEYS.INVALID_EMAIL_FORMAT.value,
              ),
            },
          })}
        />
        <UiTextarea
          wrapperClassname="w-full"
          error={errors?.text?.message}
          label={capitalize(
            t(LANG_KEYS.SUGGESTION.key, LANG_KEYS.SUGGESTION.value),
          )}
          placeholder={capitalize(
            t(
              LANG_KEYS
                .WE_VALUE_YOUR_INPUT_PLEASE_SHARE_YOUR_SUGGESTIONS_ON_HOW_WE_CAN_IMPROVE_AUDIONOTES_SERVICE
                .key,
              LANG_KEYS
                .WE_VALUE_YOUR_INPUT_PLEASE_SHARE_YOUR_SUGGESTIONS_ON_HOW_WE_CAN_IMPROVE_AUDIONOTES_SERVICE
                .value,
            ),
          )}
          {...register("text", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
          })}
        />

        <UiButton
          loading={isLoading}
          type="submit"
          className="w-full"
          variant="main"
        >
          <div>{capitalize(t(LANG_KEYS.SEND.key, LANG_KEYS.SEND.value))}</div>
        </UiButton>
      </Form>
    </UiPaper>
  );
};

const Title = styled.h2`
  color: #111827;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
`;
