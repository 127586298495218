import styled from "styled-components";
import { UiLogo } from "@/shared/ui";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUserStore } from "@/entities";
import { SocialAuth } from "@/features";
import { ROUTER_PATHS } from "@/shared/constants";

export const UiAuthLayout = () => {
  const { email } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      navigate(ROUTER_PATHS.HOME, { replace: true });
    }
  }, [email]);

  if (email) return null;

  return (
    <Wrapper>
      <Content>
        <Link to={ROUTER_PATHS.HOME}>
          <UiLogo hoverEffect fontSize={19} />
        </Link>
        <Outlet />
        <OAuth>
          <SocialAuth />
        </OAuth>
      </Content>
      <Poster>
        <img src="/auth-poster.jpg" alt="#" />
      </Poster>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  padding: 48px 96px;
  background-color: var(--auth-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 575px;
  height: 100vh;

  @media screen and (max-width: 1025px) {
    min-width: unset;
    flex: 1;
    justify-content: center;
  }

  @media screen and (max-width: 575px) {
    padding: 24px;
  }
`;

const Poster = styled.div`
  width: 100%;
  height: 100vh;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1025px) {
    display: none;
  }
`;

const OAuth = styled.div`
  margin-top: 40px;
`;
