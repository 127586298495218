export const checkAudioDuration = async (
  file: File,
): Promise<{ fileDuration: undefined | number; message: string }> => {
  return await new Promise((resolve) => {
    const audio = new Audio();
    const objectUrl = URL.createObjectURL(file);
    audio.src = objectUrl;

    audio.onloadedmetadata = () => {
      if (audio.duration < 3) {
        URL.revokeObjectURL(objectUrl);
        resolve({ fileDuration: audio.duration, message: "Less than 3" });
      }
      URL.revokeObjectURL(objectUrl);
      resolve({ fileDuration: audio.duration, message: "More than 3" });
    };

    audio.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      resolve({
        message: "Error loading audio file.",
        fileDuration: undefined,
      });
    };
  });
};
