import { create } from "zustand";
import {
  GetRecordOptionsDtoRes,
  SummaryStyleItem,
  userControllerGetRecordOptions,
} from "@/shared/api/generated";
import { devtools } from "zustand/middleware";

type Store = GetRecordOptionsDtoRes & {
  setOptions: (dto: GetRecordOptionsDtoRes) => void;
  setSummaryStyle: (data: SummaryStyleItem[]) => void;
  invalidateOptions: () => Promise<void>;
};

export const useRecordOptionsStore = create<Store>()(
  devtools(
    (set) => ({
      notesLang: "AUTODETECT",
      numberOfSpeakers: "AUTODETECT",
      summaryLength: "MEDIUM",
      summaryView: "PARAGRAPH_FORM",
      summaryStyle: [],
      selectedRecordSummaryStyleId: "",

      setOptions: (dto: GetRecordOptionsDtoRes) => {
        set(dto);
      },

      setSummaryStyle: (data: SummaryStyleItem[]) => {
        set(() => ({
          summaryStyle: data,
        }));
      },

      invalidateOptions: async () => {
        const data = await userControllerGetRecordOptions();
        set(data);
      },
    }),
    { name: "RecordOptionsStore" },
  ),
);
