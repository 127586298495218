import styled from "styled-components";
import { UiPricingLifetimeCard } from "@/shared/ui";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useAutoTranslation, useUserStore } from "@/entities";
import { handleCheckout } from "@/features";
import { useNavigate } from "react-router-dom";
import { NewCheckoutSessionDtoReqPlan } from "@/shared/api/generated.ts";

export const PricingLifetimeCards = () => {
  const { t } = useAutoTranslation();
  const { email } = useUserStore();
  const navigate = useNavigate();

  const onCheckout = async (checkoutPlan: NewCheckoutSessionDtoReqPlan) => {
    if (!email) {
      navigate(ROUTER_PATHS.AUTH_SIGN_UP);
      return;
    }

    await handleCheckout(checkoutPlan, "LIFETIME");
  };

  return (
    <Wrapper>
      <UiPricingLifetimeCard
        onCheckout={() => onCheckout("ADVANCED")}
        title={t(
          LANG_KEYS.LIFETIME_PAYMENT_FOR_ADVANCED_PLAN.key,
          LANG_KEYS.LIFETIME_PAYMENT_FOR_ADVANCED_PLAN.value,
        )}
        price={129}
        optionList="ADVANCED"
      />
      <UiPricingLifetimeCard
        onCheckout={() => onCheckout("PRO")}
        title={t(
          LANG_KEYS.LIFETIME_PAYMENT_FOR_PRO_PLAN.key,
          LANG_KEYS.LIFETIME_PAYMENT_FOR_PRO_PLAN.value,
        )}
        price={289}
        optionList="PRO"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
