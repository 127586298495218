import {
  authControllerUpdateUserEmail as mutationFn,
  UpdateEmailDtoReq,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useUserStore } from "@/entities";

type FormData = UpdateEmailDtoReq;
export function useNewEmailCodeConfForm(handleRes: (isRight: boolean) => void) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const userStore = useUserStore();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess({ isRight, access_token }) {
      handleRes(isRight);

      if (isRight && access_token) {
        userStore.login(access_token);
        reset();
      }
    },
  });

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
