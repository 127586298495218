import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  bgColor?: string;
  textColor?: string;
  className?: string;
}

interface WrapperProps {
  $bgColor: string;
  $textColor: string;
}

export const UiAlert: FC<Props> = ({
  bgColor = "#4f46e5",
  textColor = "#fff",
  children,
  rightChildren,
  leftChildren,
  className,
}) => {
  return (
    <Wrapper $bgColor={bgColor} $textColor={textColor} className={className}>
      <Left>{!!leftChildren && leftChildren}</Left>
      <Center>{!!children && children}</Center>
      <Right>{!!rightChildren && rightChildren}</Right>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  padding: 14px;
  font-size: 14px;
  gap: 10px;
  justify-content: space-between;
  background-color: ${(props) => props.$bgColor};
  color: ${(props) => props.$textColor};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  text-align: center;
`;

const Left = styled(Section)`
  justify-content: flex-start;
`;
const Center = styled(Section)`
  justify-content: center;
`;
const Right = styled(Section)`
  justify-content: flex-end;
`;
