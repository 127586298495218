import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { LangListItem } from "@/shared/api/generated.ts";

interface Store {
  lang: string;
  setLang: (lang: string) => void;
  //
  data: LangListItem[];
  setData: (data: LangListItem[]) => void;
}

export const useLangStore = create<Store>()(
  devtools(
    persist(
      (set) => ({
        lang: "en",
        setLang: (lang: string) => set({ lang }),
        data: [],
        setData: (data: LangListItem[]) => set({ data }),
      }),
      {
        name: "localization-store",
      },
    ),
    { name: "LangStore", enabled: true },
  ),
);
