import { FC, useEffect, useRef, useState } from "react";
import { SelectOption } from "@/shared/types.ts";
import { v4 } from "uuid";
import styled, { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { UiLink } from "@/shared/ui/ui-link.tsx";
import { FaChevronDown } from "react-icons/fa";

interface Props {
  options: SelectOption[];
  defaultValue?: string;
  onSelect?: (option: SelectOption) => void;
}

export const UiSelect: FC<Props> = ({ options, defaultValue, onSelect }) => {
  const [uniqueKey] = useState<string>(v4());
  const [isOpen, setOpen] = useState(false);

  const [currentIdx, setCurrentIdx] = useState<number>(0);

  const wrapperRef = useRef<any>();

  const toggle = () => setOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !wrapperRef.current ||
      wrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleSelect = (value: SelectOption, i: number) => {
    setOpen(false);
    setCurrentIdx(i);
    if (onSelect) {
      onSelect(value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const idx = options.findIndex((item) => item.value === defaultValue);
      if (idx >= 0) {
        setCurrentIdx(idx);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [defaultValue, options]);

  return (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
      <Wrapper ref={wrapperRef}>
        <Label onClick={toggle}>
          <UiLink linkHover={false} fontSize={16}>
            <div className="capitalize">{options[currentIdx].name}</div>
          </UiLink>
          <Arrow isOpen={isOpen}>
            <FaChevronDown color="#9ca3af" size={12} />
          </Arrow>
        </Label>
        <List isOpen={isOpen}>
          {options.map((item, i) => (
            <ListItem
              onClick={() => handleSelect(item, i)}
              key={`ui-selectable-${uniqueKey}-item-${i}`}
            >
              <UiLink fontSize={16}>
                <div className="capitalize">{item.name}</div>
              </UiLink>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </StyleSheetManager>
  );
};

interface ArrowProps {
  isOpen: boolean;
}
interface ListProps {
  isOpen: boolean;
}

const Wrapper = styled.div``;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 5px;

  &:hover {
    background-color: #f9fafb;
  }
`;
const List = styled.ul<ListProps>`
  transition: all 0.3s ease;
  overflow: ${(props) => (props.isOpen ? "auto" : "hidden")};
  max-height: ${(props) => (props.isOpen ? "300px" : "0px")};
`;

const ListItem = styled.li`
  padding: 10px 16px 10px 22px;
  border-radius: 5px;

  &:hover {
    background-color: #f9fafb;
  }
`;

const Arrow = styled.div<ArrowProps>`
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;
