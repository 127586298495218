import styled from "styled-components";
import { ChatForm } from "./form";
import { FC } from "react";

interface Props {
  noteId: string;
}

export const ChatNoteForm: FC<Props> = ({ noteId }) => {
  return (
    <ItemWrapper onClick={(e) => e.stopPropagation()}>
      <ChatForm noteId={noteId} />
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  width: 90%;
  height: 90%;
`;
