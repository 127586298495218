import { SVGProps } from "react";

export const IconChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    aria-hidden="true"
    className="h-6 w-5 flex-none text-indigo-600"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143z"
      clipRule="evenodd"
    />
  </svg>
);
