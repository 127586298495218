import styled from "styled-components";
import { useRequestResetPassForm } from "./model.ts";
import { UiButton, UiInput } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants/lang-keys.ts";
import { useAutoTranslation } from "@/entities";
import { capitalize } from "@/shared/utils";

export const ResetRequestPasswordForm = () => {
  const { errors, handleSubmit, register, isLoading } =
    useRequestResetPassForm();
  const { t } = useAutoTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <Inputs>
        <UiInput
          label={t(LANG_KEYS.EMAIL_ADDRESS.key, LANG_KEYS.EMAIL_ADDRESS.value)}
          error={errors?.email?.message}
          {...register("email", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t(
                LANG_KEYS.INVALID_EMAIL_FORMAT.key,
                LANG_KEYS.INVALID_EMAIL_FORMAT.value,
              ),
            },
          })}
        />
      </Inputs>

      <UiButton
        type="submit"
        variant="main"
        className="w-full"
        loading={isLoading}
      >
        <div>
          {capitalize(t(LANG_KEYS.SEND_EMAIL.key, LANG_KEYS.SEND_EMAIL.value))}
        </div>
      </UiButton>
    </form>
  );
};

const Inputs = styled.div`
  margin-bottom: 24px;
`;
