import { FC, KeyboardEvent, useState } from "react";
import { useEditCustomPromptForm } from "./model";
import styled from "styled-components";
import { UiButton, UiInput, UiShow, UiTextarea } from "@/shared/ui";
import {
  EditCustomSumaryStyleDtoReq,
  userControllerDeleteCustomSummaryStyle,
} from "@/shared/api/generated";
import { useMutation } from "@tanstack/react-query";
import { useAutoTranslation, useRecordOptionsStore } from "@/entities";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

interface Props {
  defaultValues: EditCustomSumaryStyleDtoReq;
  setSelectedId: (id: string) => void;
  updatedOnEdit: (title: string, text: string) => void;
}

export const EditCustomPromptForm: FC<Props> = ({
  defaultValues,
  setSelectedId,
  updatedOnEdit,
}) => {
  const [isEditable, setEditable] = useState(false);
  const openEdit = () => setEditable(true);
  const closeEdit = () => {
    setEditable(false);
    reset();
  };

  const handleEditFinish = (title: string, text: string) => {
    setSelectedId(defaultValues.id);
    closeEdit();
    updatedOnEdit(title, text);
  };

  const { handleSubmit, errors, isLoading, register, reset } =
    useEditCustomPromptForm(defaultValues, handleEditFinish);

  const recordOptionsStore = useRecordOptionsStore();

  const deleteMutation = useMutation({
    mutationFn: () => userControllerDeleteCustomSummaryStyle(defaultValues.id),
    async onSuccess(res) {
      recordOptionsStore.setSummaryStyle(res.summaryStyle);
      setSelectedId(res.selectedRecordSummaryStyleId);
    },
  });

  const { t } = useAutoTranslation();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleClickDelete = () => {
    const isSure = confirm(
      `${t(LANG_KEYS.DELETING_CUSTOM_PROMPT.key, LANG_KEYS.DELETING_CUSTOM_PROMPT.value)} "${defaultValues.title}"?`,
    );

    if (!isSure) return;

    deleteMutation.mutate();
  };

  return (
    <Form>
      <Content>
        <UiInput
          error={errors?.title?.message}
          onKeyDown={handleKeyDown}
          disabled={!isEditable}
          {...register("title", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
          })}
        />
        <UiTextarea
          disabled={!isEditable}
          error={errors?.text?.message}
          {...register("text", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
          })}
        />
      </Content>

      <UiShow>
        <UiShow.When isTrue={!isEditable}>
          <Footer>
            <UiButton
              type="button"
              className="flex-1"
              variant="main"
              onClick={openEdit}
            >
              <div>
                {capitalize(t(LANG_KEYS.EDIT.key, LANG_KEYS.EDIT.value))}
              </div>
            </UiButton>
            <UiButton
              type="button"
              className="flex-1"
              variant="white"
              onClick={handleClickDelete}
              loading={deleteMutation.isPending}
            >
              <div>
                {capitalize(t(LANG_KEYS.DELETE.key, LANG_KEYS.DELETE.value))}
              </div>
            </UiButton>
          </Footer>
        </UiShow.When>
        <UiShow.When isTrue={isEditable}>
          <Footer>
            <UiButton
              onClick={handleSubmit}
              type="button"
              className="flex-1"
              variant="main"
              loading={isLoading}
            >
              <div>
                {capitalize(t(LANG_KEYS.SAVE.key, LANG_KEYS.SAVE.value))}
              </div>
            </UiButton>
            <UiButton
              type="button"
              className="flex-1"
              variant="white"
              onClick={closeEdit}
            >
              <div>
                {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
              </div>
            </UiButton>
          </Footer>
        </UiShow.When>
      </UiShow>
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 30px;
`;
