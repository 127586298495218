import styled from "styled-components";
import { TextForm } from "./form";

export const TextNoteForm = () => {
  return (
    <ItemWrapper onClick={(e) => e.stopPropagation()}>
      <TextForm />
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  width: 90%;
  height: 90%;
`;
