import { Children, ReactNode } from "react";

type UiShowWhenProps = {
  children: string | JSX.Element | JSX.Element[];
  isTrue: boolean;
};

type UiShowElseProps = {
  children: string | JSX.Element | JSX.Element[];
  render?: any;
};

export const UiShow = (props: { children: JSX.Element | JSX.Element[] }) => {
  let when: ReactNode | null = null;
  let otherwise: ReactNode | null = null;

  Children.forEach(props.children, (children) => {
    if (children.props.isTrue === undefined) {
      otherwise = children;
    } else if (!when && children.props.isTrue) {
      when = children;
    }
  });

  return when || otherwise;
};

UiShow.When = ({ isTrue, children }: UiShowWhenProps) => isTrue && children;
UiShow.Else = ({ render, children }: UiShowElseProps) => render || children;
