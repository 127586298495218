import {
  NewCheckoutSessionDtoReqPeriod,
  NewCheckoutSessionDtoReqPlan,
  stripeControllerNewCheckout,
} from "@/shared/api/generated.ts";

export const handleCheckout = async (
  plan: NewCheckoutSessionDtoReqPlan,
  period: NewCheckoutSessionDtoReqPeriod,
) => {
  const res = await stripeControllerNewCheckout({ plan, period });
  if (res.url) {
    window.location.href = res.url;
  }
};
