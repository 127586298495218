import styled from "styled-components";
import { UiInput } from "@/shared/ui";
import { FiRefreshCcw } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IntegrationDataDto,
  userControllerRecreateZapierToken,
} from "@/shared/api/generated.ts";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import { copyToClipboard } from "@/shared/utils";
import { toast } from "react-toastify";
import { useAutoTranslation } from "@/entities";

export const ZapierTokenInput = ({ token }: { token: string }) => {
  const queryClient = useQueryClient();
  const { t } = useAutoTranslation();
  const recreateTokenMutation = useMutation({
    mutationFn: userControllerRecreateZapierToken,
    async onSuccess(data: IntegrationDataDto) {
      queryClient.setQueryData(QUERY_KEYS.NOTION_DATA, data);
    },
  });

  const handleCopy = () => {
    copyToClipboard(token);
    toast.info(
      t(
        LANG_KEYS.COPIED_TO_YOUR_CLIPBOARD.key,
        LANG_KEYS.COPIED_TO_YOUR_CLIPBOARD.value,
      ),
    );
  };

  return (
    <Wrapper>
      <UiInput
        className="!pr-[70px]"
        disabled
        name="zapier-api-token"
        label="Zapier API Token"
        value={token}
      />
      <RefreshBtn
        type="button"
        onClick={() => recreateTokenMutation.mutate({})}
      >
        <FiRefreshCcw size={20} />
      </RefreshBtn>
      <CopyBtn type="button" onClick={handleCopy}>
        <MdContentCopy size={20} />
      </CopyBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const RefreshBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 15px));
  right: 40px;
`;
const CopyBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 15px));
  right: 5px;
`;
