import { AuthWidget } from "@/widgets";
import { SignUpForm } from "@/features";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

export const SignUpPage = () => {
  const { t } = useAutoTranslation();
  return (
    <AuthWidget
      title={t(LANG_KEYS.SIGN_UP_NOW.key, LANG_KEYS.SIGN_UP_NOW.value)}
      heading={t(
        LANG_KEYS.ALREADY_HAVE_AN_ACCOUNT.key,
        LANG_KEYS.ALREADY_HAVE_AN_ACCOUNT.value,
      )}
      text={t(LANG_KEYS.SIGN_IN.key, LANG_KEYS.SIGN_IN.value)}
      href="/auth/sign-in"
    >
      <SignUpForm />
    </AuthWidget>
  );
};
