import { Text, Title, Wrapper } from "../components.tsx";
import { UiButton } from "@/shared/ui";
import { Link } from "react-router-dom";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useAutoTranslation, useUtilityStore } from "@/entities";

export const NoteLimitReached = () => {
  const { t } = useAutoTranslation();
  const { setNoteLimitedAlert } = useUtilityStore();
  return (
    <Wrapper>
      <Title>
        {t(
          LANG_KEYS.NOTE_LIMIT_REACHED_TITLE.key,
          LANG_KEYS.NOTE_LIMIT_REACHED_TITLE.value,
        )}
      </Title>
      <Text>
        {t(
          LANG_KEYS.NOTE_LIMIT_REACHED_TEXT.key,
          LANG_KEYS.NOTE_LIMIT_REACHED_TEXT.value,
        )}
      </Text>
      <Link
        to={ROUTER_PATHS.AUTH_SIGN_UP}
        onClick={() => setNoteLimitedAlert({ isNoteLimitedAlert: false })}
      >
        <UiButton variant="main" className="w-full mt-5">
          <div>
            {t(
              LANG_KEYS.SIGN_UP_ITS_FREE.key,
              LANG_KEYS.SIGN_UP_ITS_FREE.value,
            )}
          </div>
        </UiButton>
      </Link>
    </Wrapper>
  );
};
