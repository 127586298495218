import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  AccountSettingsPage,
  CheckoutPage,
  ConfirmEmailPage,
  FavoriteNotesPage,
  HomePage,
  PricingPage,
  PrivacyPolicyPage,
  ProcessResetPasswordPage,
  RequestResetPasswordPage,
  SignInPage,
  SignUpPage,
  TermsOfServicePage,
  TrashNotesPage,
} from "@/pages";
import { AnimatePresence } from "framer-motion";
import { UiAuthLayout, UiMainLayout, UiUserLayout } from "@/widgets";
import { UiAccountLayout, UiTextLayout } from "@/widgets/layouts";
import { ROUTER_PATHS } from "@/shared/constants";
import { useEffect } from "react";
import { useUserStore, useUtilityStore } from "@/entities";

export const RouterSetup = () => {
  const location = useLocation();
  const {
    isRegisterForFileAlert,
    isFileLimitSizeAlert,
    isMaxFileLimitAlert,
    isLengthAudioSizeError,
    setRegisterForFileAlert,
    setFileLimitSizeAlert,
    setMaxFileLimitAlert,
    setLengthAudioSizeError,
    clearAll,
  } = useUtilityStore();

  const { access_token } = useUserStore();

  useEffect(() => {
    clearAll();
  }, [access_token]);

  useEffect(() => {
    if (isRegisterForFileAlert) {
      setRegisterForFileAlert({ isRegisterForFileAlert: false });
    }

    if (isFileLimitSizeAlert) {
      setFileLimitSizeAlert({ isFileLimitSizeAlert: false });
    }

    if (isMaxFileLimitAlert) {
      setMaxFileLimitAlert({ isMaxFileLimitAlert: false });
    }

    if (isLengthAudioSizeError) {
      setLengthAudioSizeError({ isLengthAudioSizeError: false });
    }
  }, [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location}>
        <Route path={ROUTER_PATHS.HOME} element={<UiMainLayout />}>
          <Route index element={<HomePage />} />
          <Route path={ROUTER_PATHS.PRICING} element={<PricingPage />} />
          <Route
            path={ROUTER_PATHS.FAVORITE_NOTES}
            element={<FavoriteNotesPage />}
          />
          <Route
            path={ROUTER_PATHS.DELETED_NOTES}
            element={<TrashNotesPage />}
          />
        </Route>

        <Route path="" element={<UiTextLayout />}>
          <Route
            path={ROUTER_PATHS.PRIVACY_POLICY}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={ROUTER_PATHS.TERMS_OF_SERVICE}
            element={<TermsOfServicePage />}
          />
        </Route>

        <Route path={ROUTER_PATHS.CHECKOUT} element={<CheckoutPage />} />

        <Route path={ROUTER_PATHS.AUTH} element={<UiAuthLayout />}>
          <Route index element={<Navigate to={ROUTER_PATHS.HOME} replace />} />
          <Route path={ROUTER_PATHS.AUTH_SIGN_UP} element={<SignUpPage />} />
          <Route path={ROUTER_PATHS.AUTH_SIGN_IN} element={<SignInPage />} />
          <Route
            path={ROUTER_PATHS.AUTH_RESET_PASSWORD}
            element={<RequestResetPasswordPage />}
          />
        </Route>

        <Route path={ROUTER_PATHS.USER} element={<UiUserLayout />}>
          <Route index element={<Navigate to={ROUTER_PATHS.HOME} replace />} />
          <Route
            path={ROUTER_PATHS.USER_RESET_PASSWORD}
            element={<ProcessResetPasswordPage />}
          />
          <Route
            path={ROUTER_PATHS.USER_CONFIRM_EMAIL}
            element={<ConfirmEmailPage />}
          />
        </Route>

        <Route path={ROUTER_PATHS.ACCOUNT} element={<UiAccountLayout />}>
          <Route index element={<Navigate to={ROUTER_PATHS.HOME} replace />} />
          <Route
            path={ROUTER_PATHS.ACCOUNT_SETTINGS}
            element={<AccountSettingsPage />}
          />
        </Route>

        <Route path="*" element={<Navigate to={ROUTER_PATHS.HOME} replace />} />
      </Routes>
    </AnimatePresence>
  );
};
