import { FC } from "react";
import styled from "styled-components";
import { useProcessResetPassForm } from "./model";
import { UiButton, UiInputPass, UiLogo } from "@/shared/ui";
import { Link } from "react-router-dom";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";
import { capitalize } from "@/shared/utils";

interface Props {
  code: string;
  email: string;
}

export const ResetProcessPasswordForm: FC<Props> = ({ code, email }) => {
  const { errors, handleSubmit, register, isLoading } =
    useProcessResetPassForm(code);
  const { t } = useAutoTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Link to={ROUTER_PATHS.HOME}>
        <div className="flex justify-center">
          <UiLogo hoverEffect />
        </div>
      </Link>
      <h2 className="text-center text-2xl">
        {t(
          LANG_KEYS.RESET_YOUR_PASSWORD.key,
          LANG_KEYS.RESET_YOUR_PASSWORD.value,
        )}
        <br />
        {t(LANG_KEYS.FOR_EMAIL.key, LANG_KEYS.FOR_EMAIL.value)}: {email}
      </h2>
      <UiInputPass
        id="password"
        label={t(LANG_KEYS.PASSWORD.key, LANG_KEYS.PASSWORD.value)}
        error={errors?.password?.message}
        {...register("password", {
          required: {
            value: true,
            message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
          },
          minLength: {
            value: 6,
            message: t(
              LANG_KEYS.MIN_PASS_LENGTH.key,
              LANG_KEYS.MIN_PASS_LENGTH.value,
            ),
          },
        })}
      />
      <UiInputPass
        id="confirmPassword"
        label={t(
          LANG_KEYS.CONFIRM_PASSWORD.key,
          LANG_KEYS.CONFIRM_PASSWORD.value,
        )}
        error={errors?.confirmPassword?.message}
        {...register("confirmPassword", {
          required: {
            value: true,
            message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
          },
          minLength: {
            value: 6,
            message: t(
              LANG_KEYS.MIN_PASS_LENGTH.key,
              LANG_KEYS.MIN_PASS_LENGTH.value,
            ),
          },
        })}
      />

      <UiButton
        variant="main"
        className="w-full"
        type="submit"
        loading={isLoading}
      >
        <div>
          {capitalize(t(LANG_KEYS.CONFIRM.key, LANG_KEYS.CONFIRM.value))}
        </div>
      </UiButton>
    </Form>
  );
};

const Form = styled.form`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 425px) {
    min-width: 90%;
  }
`;
