import { AuthWidget } from "@/widgets";
import { LoginForm } from "@/features";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

export const SignInPage = () => {
  const { t } = useAutoTranslation();
  return (
    <AuthWidget
      title={t(
        LANG_KEYS.SIGN_IN_TO_YOUR_ACCOUNT.key,
        LANG_KEYS.SIGN_IN_TO_YOUR_ACCOUNT.value,
      )}
      heading={t(LANG_KEYS.NOT_A_MEMBER.key, LANG_KEYS.NOT_A_MEMBER.value)}
      text={t(LANG_KEYS.SIGN_UP_NOW.key, LANG_KEYS.SIGN_UP_NOW.value)}
      href="/auth/sign-up"
    >
      <LoginForm />
    </AuthWidget>
  );
};
