import { create } from "zustand";
import { devtools } from "zustand/middleware";

type Store = {
  isOpen: boolean;
  showModal: () => void;
  closeModal: () => void;
};

export const useChangeEmailModalStore = create<Store>()(
  devtools(
    (set) => ({
      isOpen: false,
      showModal: () => {
        set(() => ({
          isOpen: true,
        }));
      },
      closeModal: () => {
        set(() => ({
          isOpen: false,
        }));
      },
    }),
    { name: "ChangeMyEmailModalStore" },
  ),
);
