import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const UiTextLayout = () => {
  return (
    <Section>
      <Inner>
        <Outlet />
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  max-width: 1150px;
  margin: 75px auto;

  @media screen and (max-width: 1200px) {
    width: 90%;
  }
`;

const Inner = styled.div`
  border: 4px solid rgb(247, 245, 242);
  padding: 30px;

  h1 {
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  h3 {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 8px;
  }

  ul {
    list-style-type: disc;
    margin-left: 37px;
  }

  li {
    font-size: 15px;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
    font-size: 15px;
  }

  p {
    margin-bottom: 23px;
    font-size: 15px;
  }
`;
