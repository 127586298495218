import { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { IconExpand } from "@/shared/ui/icons";
import { GetRecordOptionsDtoResSummaryView } from "@/shared/api/generated.ts";
import { formatDate } from "@/shared/utils/format-date.ts";
import { useAutoTranslation } from "@/entities";
import { LANG_KEYS } from "@/shared/constants";
import { UiNoteActionWrapper } from "@/shared/ui";
import { copyToClipboard } from "@/shared/utils";
import { toast } from "react-toastify";

type Action = ReactNode | "COPY";

interface Props {
  id: string;
  title: string;
  original: string;
  paragraph: string;
  list: string;
  audioUrl: string;
  createdAt: string;
  onExpand: () => void;
  summaryView: GetRecordOptionsDtoResSummaryView;
  actions?: Action[];
  maxHeightContent?: string;
  actionItemFlexFull?: boolean;
  actionsGap?: number;
}

enum Mode {
  NOTES,
  ORIGINAL,
  PARAGRAPH,
}

export const UiNoteCard: FC<Props> = ({
  id,
  actions = [],
  createdAt,
  onExpand,
  paragraph,
  title,
  list,
  original,
  summaryView,
  maxHeightContent = "150px",
  actionItemFlexFull,
  actionsGap = 0,
}) => {
  const [text, setText] = useState(paragraph);
  const [mode, setMode] = useState<Mode>(Mode.PARAGRAPH);

  const { t } = useAutoTranslation();

  useEffect(() => {
    let currentText = "";
    switch (mode) {
      case Mode.NOTES:
        currentText = list;
        break;
      case Mode.PARAGRAPH:
        currentText = paragraph;
        break;
      case Mode.ORIGINAL:
        currentText = original;
        break;
    }
    setText(currentText);
  }, [mode, list, paragraph, original]);

  useEffect(() => {
    if (summaryView) {
      switch (summaryView) {
        case "BULLET_POINTS":
          setMode(Mode.NOTES);
          break;
        case "PARAGRAPH_FORM":
          setMode(Mode.PARAGRAPH);
          break;
      }
    }
  }, [summaryView]);

  const handleToggleNotes = () => {
    if (mode == Mode.NOTES) {
      setMode(Mode.PARAGRAPH);
    } else {
      setMode(Mode.NOTES);
    }
  };

  const handleToggleMode = () => {
    if (mode == Mode.ORIGINAL || mode == Mode.NOTES) {
      setMode(Mode.PARAGRAPH);
    } else {
      setMode(Mode.ORIGINAL);
    }
  };

  const handleCopy = () => {
    copyToClipboard(`${title}\n${text}`);
    toast.success(
      t(
        LANG_KEYS.COPIED_TO_YOUR_CLIPBOARD.key,
        LANG_KEYS.COPIED_TO_YOUR_CLIPBOARD.value,
      ),
    );
  };

  return (
    <Wrapper className="shadow-sm">
      <div>
        <Top>
          <Title>{title}</Title>
          <ExpandBtn type="button" onClick={onExpand}>
            <IconExpand />
          </ExpandBtn>
        </Top>

        <Content $maxHeight={maxHeightContent}>
          <p>{text}</p>
        </Content>
      </div>

      <div>
        <Actions $actionsGap={actionsGap}>
          {actions.map((item, i) => {
            if (!item) return null;

            if (item == "COPY") {
              return (
                <ActionItem
                  key={`note-${id}-action-${i}`}
                  $flexFull={!!actionItemFlexFull}
                >
                  <UiNoteActionWrapper
                    onClick={handleCopy}
                    label={t(
                      LANG_KEYS.COPY_TEXT.key,
                      LANG_KEYS.COPY_TEXT.value,
                    )}
                  >
                    <img src="/copy-icon.svg" alt="" />
                  </UiNoteActionWrapper>
                </ActionItem>
              );
            }
            return (
              <ActionItem
                key={`note-${id}-action-${i}`}
                $flexFull={!!actionItemFlexFull}
              >
                {item}
              </ActionItem>
            );
          })}
        </Actions>

        <Footer>
          <div className="left">
            <ToggleNoteBtn type="button" onClick={handleToggleNotes}>
              <img
                src={
                  mode != Mode.NOTES ? "/list-icon.svg" : "/paragraph-icon.svg"
                }
                alt=""
              />
            </ToggleNoteBtn>
            <ToggleModeBtn type="button" onClick={handleToggleMode}>
              {mode == Mode.ORIGINAL
                ? t(LANG_KEYS.VIEW_SUMMARY.key, LANG_KEYS.VIEW_SUMMARY.value)
                : t(
                    LANG_KEYS.VIEW_ORIGINAL_TRANSCRIPT.key,
                    LANG_KEYS.VIEW_ORIGINAL_TRANSCRIPT.value,
                  )}
            </ToggleModeBtn>
          </div>
          <div className="date">{formatDate(createdAt)}</div>
        </Footer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 22px;
  border: 1px solid rgb(222, 226, 230);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 30px;
`;

const ExpandBtn = styled.button`
  position: absolute;
  top: 7px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled.div<{ $maxHeight: string }>`
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 30px;
  margin-bottom: 10px;
  max-height: ${(props) => props.$maxHeight};
  overflow-y: auto;
  flex: 1;

  p {
    white-space: pre-wrap;
  }
`;

const Actions = styled.div<{ $actionsGap: number }>`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 30px;
  margin-bottom: 10px;

  ${(props) => props.$actionsGap && `gap: ${props.$actionsGap}px`}
`;

const ActionItem = styled.div<{ $flexFull: boolean }>`
  ${(props) =>
    props.$flexFull &&
    `
    flex: 1;
  `}
`;

const Footer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(229, 231, 235);

  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .date {
    color: rgb(108, 114, 127);
    font-size: 13px;
    font-weight: 400;
  }
`;

const ToggleNoteBtn = styled.button`
  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
`;

const ToggleModeBtn = styled.button`
  color: rgb(107, 114, 128);
  font-size: 14px;
  font-style: italic;
  text-align: left;

  &:hover {
    color: #4b5563;
  }
`;
