import styled from "styled-components";
import { LANG_KEYS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";
import { FC } from "react";
import { useAutoTranslation, useSuggestionModalStore } from "@/entities";

interface Props {
  triggerMenu?: boolean;
}

export const LeaveSuggestion: FC<Props> = ({ triggerMenu }) => {
  const { t } = useAutoTranslation();
  const { showModal } = useSuggestionModalStore();

  return (
    <Trigger $triggerMenu={!!triggerMenu} type="button" onClick={showModal}>
      {capitalize(
        t(LANG_KEYS.HAVE_A_SUGGESTION.key, LANG_KEYS.HAVE_A_SUGGESTION.value),
      )}
    </Trigger>
  );
};

const Trigger = styled.button<{ $triggerMenu: boolean }>`
  color: #475569;
  cursor: pointer;
  font-size: ${(props) => (props.$triggerMenu ? "16px" : "14px")};
  ${(props) =>
    props.$triggerMenu &&
    `
  padding: 10px 16px;
  width: 100%;
  text-align: left;
  `}
  &:hover {
    color: #0f172a;
  }

  &:hover {
    ${(props) =>
      props.$triggerMenu &&
      `
      background-color: #f9fafb;
      `};
  }
`;
