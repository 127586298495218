import { BASIC_INFO, LANG_KEYS } from "@/shared/constants";
import { UiButton } from "@/shared/ui";
import { useAutoTranslation } from "@/entities";

export const ConnectZapier = () => {
  const { t } = useAutoTranslation();
  return (
    <a href={BASIC_INFO.ZAPIER_INTEGRATION_LINK} target="_blank">
      <UiButton variant="orange">
        <div>
          {t(LANG_KEYS.CONNECT.key, LANG_KEYS.CONNECT.value)}
          &nbsp;Zapier
        </div>
      </UiButton>
    </a>
  );
};
