import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useChatNoteForm } from "./model.ts";
import { useAutoTranslation, useChatNoteStore, useUserStore } from "@/entities";
import { UiChatMsg, UiInputChat, UiSpinner } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { CgClose } from "react-icons/cg";
import { UiChatDecor } from "@/shared/ui/ui-chat-decor.tsx";
import { AnimatePresence, motion as m } from "framer-motion";
import { Controller } from "react-hook-form";

interface Props {
  noteId: string;
}

const MessageVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

export const ChatForm: FC<Props> = ({ noteId }) => {
  const { t } = useAutoTranslation();
  const { handleSubmit, chat, control, isLoading } = useChatNoteForm(noteId);

  const { closeChat } = useChatNoteStore();
  const { email } = useUserStore();
  const [isDecorOpen, setIsDecorOpen] = useState(true);

  const handleKeyboardPress = (e: any) => {
    if (e.keyCode == 27 || e.key == "Escape") {
      closeChat();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPress);
    // BodyNoScroll.Enable();
    // window.scrollTo({ top: 0, behavior: "instant" });
    return () => {
      window.removeEventListener("keydown", handleKeyboardPress);
      // BodyNoScroll.Disable();
    };
  }, []);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <CloseBtn type="button" onClick={closeChat}>
        <CgClose color="#000" size={28} />
      </CloseBtn>

      <Top>
        <AnimatePresence mode="wait">
          {isDecorOpen && (
            <m.div
              variants={MessageVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <Decor>
                <UiChatDecor
                  onClose={() => {
                    setIsDecorOpen(false);
                  }}
                  title={t(
                    LANG_KEYS.CHAT_WITH_YOUR_NOTES.key,
                    LANG_KEYS.CHAT_WITH_YOUR_NOTES.value,
                  )}
                  list={[
                    t(
                      LANG_KEYS.ASK_ANYTHING_ABOUT_YOUR_NOTES.key,
                      LANG_KEYS.ASK_ANYTHING_ABOUT_YOUR_NOTES.value,
                    ),
                    t(
                      LANG_KEYS
                        .START_CONVERSATIONS_WITH_AI_USING_YOUR_NOTE_CONTENT
                        .key,
                      LANG_KEYS
                        .START_CONVERSATIONS_WITH_AI_USING_YOUR_NOTE_CONTENT
                        .value,
                    ),
                    t(
                      LANG_KEYS.GENERATE_NEW_IDEAS_INSPIRED_BY_YOUR_NOTES.key,
                      LANG_KEYS.GENERATE_NEW_IDEAS_INSPIRED_BY_YOUR_NOTES.value,
                    ),
                  ]}
                />
              </Decor>
            </m.div>
          )}
        </AnimatePresence>
        <Messages>
          {chat.map((item, i) => (
            <m.div
              key={`chat-${noteId}-msg-${i}`}
              variants={MessageVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              <UiChatMsg
                fromAudioNotes={i % 2 !== 0}
                letter={email ? email[0] : "U"}
              >
                {item}
              </UiChatMsg>
            </m.div>
          ))}
          {isLoading && (
            <UiChatMsg fromAudioNotes letter="">
              <UiSpinner />
            </UiChatMsg>
          )}
        </Messages>
      </Top>

      <Bottom>
        <Controller
          control={control}
          name="message"
          rules={{ required: true }}
          render={({ field }) => (
            <UiInputChat
              name={field.name}
              onChange={(e) => field.onChange(e.target.value)}
              value={field.value}
              isLoading={isLoading}
              onSend={handleSubmit}
              placeholder={
                t(
                  LANG_KEYS.ASK_ANYTHING_ABOUT_YOUR_NOTE.key,
                  LANG_KEYS.ASK_ANYTHING_ABOUT_YOUR_NOTE.value,
                ) + "..."
              }
            />
          )}
        />
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  background-color: #fff;
  border-radius: 22px;
  border: 1px solid rgb(222, 226, 230);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  gap: 35px;
  position: relative;
`;

const Bottom = styled.div``;
const Decor = styled.div`
  width: fit-content;
  margin: 12px auto 0;
`;
const Top = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 8px;
`;
