import { Note } from "@/shared/api/generated.ts";

export const searchFilterFunc = (item: Note, q: string) => {
  if (!q) return item;
  q = q.toLowerCase();
  if (item.title.toLowerCase().includes(q)) return item;
  if (item.original.toLowerCase().includes(q)) return item;
  if (item.paragraph.toLowerCase().includes(q)) return item;
  if (item.list.toLowerCase().includes(q)) return item;
};
