import { UiButton, UiInput, UiTextarea } from "@/shared/ui";
import { FC, KeyboardEvent } from "react";
import styled from "styled-components";
import { useAddCustomPromptForm } from "./model";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

interface Props {
  onCancel: () => void;
  setSelectedId: (id: string) => void;
}

export const AddCustomPromptForm: FC<Props> = ({ onCancel, setSelectedId }) => {
  const afterSubmit = (id: string) => {
    setSelectedId(id);
    onCancel();
  };
  const { handleSubmit, register, isLoading, errors } =
    useAddCustomPromptForm(afterSubmit);

  const { t } = useAutoTranslation();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Form>
      <Content>
        <UiInput
          label={t(
            LANG_KEYS.CUSTOM_SUMMARY_STYLE_NAME.key,
            LANG_KEYS.CUSTOM_SUMMARY_STYLE_NAME.value,
          )}
          placeholder={t(
            LANG_KEYS.FOR_EXAMPLE_HIGHLIGHTS_AND_KEY_TAKEAWAYS.key,
            LANG_KEYS.FOR_EXAMPLE_HIGHLIGHTS_AND_KEY_TAKEAWAYS.value,
          )}
          error={errors?.title?.message}
          onKeyDown={handleKeyDown}
          {...register("title", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
          })}
        />

        <UiTextarea
          label={t(
            LANG_KEYS.CUSTOM_SUMMARY_STYLE_PROMPT.key,
            LANG_KEYS.CUSTOM_SUMMARY_STYLE_PROMPT.value,
          )}
          placeholder={t(
            LANG_KEYS
              .FOR_EXAMPLE_CHERRY_PICK_THE_MOST_STRIKING_ESSENTIAL_ELEMENTS_FROM_THE_RECEIVED_TEXT_CONCENTRATE_ON_SYNTHESIZING_THESE_INTO_POWERFUL_HIGHLIGHTS_AND_TAKEAWAYS_THAT_STAND_OUT_THE_SUMMARY_SHOULD_BE_A_LIGHTHOUSE_GUIDING_READERS_TO_THE_PIVOTAL_POINTS_PAINTED_VIVIDLY_AND_ENGAGINGLY
              .key,
            LANG_KEYS
              .FOR_EXAMPLE_CHERRY_PICK_THE_MOST_STRIKING_ESSENTIAL_ELEMENTS_FROM_THE_RECEIVED_TEXT_CONCENTRATE_ON_SYNTHESIZING_THESE_INTO_POWERFUL_HIGHLIGHTS_AND_TAKEAWAYS_THAT_STAND_OUT_THE_SUMMARY_SHOULD_BE_A_LIGHTHOUSE_GUIDING_READERS_TO_THE_PIVOTAL_POINTS_PAINTED_VIVIDLY_AND_ENGAGINGLY
              .value,
          )}
          error={errors?.text?.message}
          {...register("text", {
            required: {
              value: true,
              message: t(LANG_KEYS.REQUIRED.key, LANG_KEYS.REQUIRED.value),
            },
          })}
        />
      </Content>
      <Footer>
        <UiButton
          loading={isLoading}
          type="button"
          onClick={handleSubmit}
          className="flex-1"
          variant="main"
        >
          <div>{capitalize(t(LANG_KEYS.ADD.key, LANG_KEYS.ADD.value))}</div>
        </UiButton>
        <UiButton
          type="button"
          className="flex-1"
          variant="white"
          onClick={onCancel}
        >
          <div>
            {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
          </div>
        </UiButton>
      </Footer>
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 30px;
`;
