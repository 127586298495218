import { FC, ReactNode, useEffect } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  className?: string;
  handleClose?: () => void;
}

export const UiBackdrop: FC<Props> = ({ children, handleClose, className }) => {
  const onClose = () => {
    // BodyNoScroll.Disable();
    if (handleClose) {
      handleClose();
    }
  };

  const handleKeyboardPress = (e: any) => {
    if (e.keyCode == 27 || e.key == "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPress);
    // BodyNoScroll.Enable();
    return () => {
      window.removeEventListener("keydown", handleKeyboardPress);
    };
  }, []);

  return (
    <Wrapper className={className} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="w-fit">
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;
