import { FC } from "react";
import { useAutoTranslation } from "@/entities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Note, notesControllerDeleteNote } from "@/shared/api/generated.ts";
import { UiNoteActionWrapper } from "@/shared/ui";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";
import styled from "styled-components";
import { MdDeleteForever } from "react-icons/md";
import { sortNewest } from "@/shared/utils";

interface Props {
  id: string;
}

export const NoteRemove: FC<Props> = ({ id }) => {
  const { t } = useAutoTranslation();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: notesControllerDeleteNote,
  });

  const handleSubmit = () => {
    deleteMutation.mutate(id);
    changeInCache();
  };

  const changeInCache = () => {
    const trashData = queryClient.getQueryData(QUERY_KEYS.DELETED) as Note[];
    const updatedTrash = JSON.parse(JSON.stringify(trashData)) as Note[];
    queryClient.setQueryData(QUERY_KEYS.DELETED, () => {
      return updatedTrash.filter((item) => item.id != id).sort(sortNewest);
    });
  };

  return (
    <UiNoteActionWrapper
      onClick={handleSubmit}
      label={t(LANG_KEYS.DELETE_FOREVER.key, LANG_KEYS.DELETE_FOREVER.value)}
      btnClassname="!w-full"
    >
      <Wrapper>
        <MdDeleteForever size={22} />
        <Text>
          {t(LANG_KEYS.DELETE_FOREVER.key, LANG_KEYS.DELETE_FOREVER.value)}
        </Text>
      </Wrapper>
    </UiNoteActionWrapper>
  );
};

const Text = styled.div`
  font-size: 12px;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 15px 10px;
`;
