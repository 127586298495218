export const PLANS_DATA = {
  FREE: {
    name: "FREE",
    priceId: "",
  },
  ADVANCED: {
    name: "ADVANCED",
    priceId: "",
  },
  PRO: {
    name: "PRO",
    priceId: "",
  },
};
