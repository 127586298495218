import { forwardRef, InputHTMLAttributes, PropsWithRef, useState } from "react";
import styled from "styled-components";
import { UiValidationError } from "./ui-validation-error";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { StyledTextField } from "./styled";

type Props = {
	name: string;
	id: string;
	label?: string;
	value?: string;
	error?: string;
	placeholder?: string;
} & PropsWithRef<InputHTMLAttributes<HTMLInputElement>>;

export const UiInputPass = forwardRef<HTMLInputElement, Props>(
	({ placeholder = "", error, id, name, value, label, ...props }, ref) => {
		const [show, setShow] = useState(false);

		const toggle = () => setShow((prev) => !prev);

		return (
			<Wrapper>
				{!!label && <Label htmlFor={id}>{label}</Label>}
				<Input
					value={value}
					type={show ? "text" : "password"}
					name={name}
					id={id}
					placeholder={placeholder}
					$error={!!error}
					ref={ref}
					{...props}
				/>
				<Icon $label={!!label} onClick={toggle}>
					{!show ? <FaRegEye /> : <FaRegEyeSlash />}
				</Icon>
				{!!error && <UiValidationError message={error} />}
			</Wrapper>
		);
	}
);

UiInputPass.displayName = "UiInputPass";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Label = styled.label`
	color: var(--dark);
	font-size: 14px;
	font-weight: 500;
`;

const Input = styled(StyledTextField)``;

const Icon = styled.div<{ $label: boolean }>`
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: ${({ $label }) => ($label ? "38px" : "10px")};
`;
