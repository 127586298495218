import { FC, useState } from "react";
import styled from "styled-components";
import { UiButton, UiInput, UiShow } from "@/shared/ui";
import { IconQuestion } from "@/shared/ui/icons";
import { useMutation } from "@tanstack/react-query";
import { userControllerStartChangeEmail } from "@/shared/api/generated.ts";
import { useChangeEmailFirstCodeConfForm } from "./first-code-confirmation.ts";
import { useProvideNewEmailForm } from "./provide-new-email.ts";
import { useNewEmailCodeConfForm } from "./new-email-code-confirmation.ts";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants/lang-keys.ts";
import { useAutoTranslation } from "@/entities";

interface Props {
  onClose: () => void;
}

type Step = "IS_READY" | "CONFIRM_CODE" | "NEW_EMAIL" | "CONF_NEW_EMAIL";
export const ChangeProcess: FC<Props> = ({ onClose }) => {
  const [step, setStep] = useState<Step>("IS_READY");
  const [invalidFirstCode, setInvalidFirstCode] = useState(false);
  const [invalidSecondCode, setInvalidSecondCode] = useState(false);
  const [firstRightCode, setFirstRightCode] = useState("");
  const { t } = useAutoTranslation();

  const handleFirstCodeRes = (isRight: boolean, code: string) => {
    if (isRight) {
      setInvalidFirstCode(false);
      setFirstRightCode(code);
      setStep("NEW_EMAIL");
    } else {
      setInvalidFirstCode(true);
    }
  };

  const handleSecondCodeRes = (isRight: boolean) => {
    setInvalidSecondCode(!isRight);
    if (isRight) {
      onClose();
    }
  };

  const {
    handleSubmit: submitFirstCodeConf,
    errors: errorsFirstCodeConf,
    register: registerFirstCodeConf,
    isLoading: firstCodeConfLoading,
  } = useChangeEmailFirstCodeConfForm(handleFirstCodeRes);

  const {
    handleSubmit: submitNewEmail,
    errors: errorsNewEmail,
    register: registerNewEmail,
    isLoading: newEmailLoading,
  } = useProvideNewEmailForm(firstRightCode, () => {
    setStep("CONF_NEW_EMAIL");
  });

  const {
    handleSubmit: submitConfNewEmail,
    errors: errorsConfNewEmail,
    register: registerConfNewEmail,
    isLoading: confNewEmailLoading,
  } = useNewEmailCodeConfForm(handleSecondCodeRes);

  const startChangeEmailMutation = useMutation({
    mutationFn: userControllerStartChangeEmail,
    async onSuccess() {
      setStep("CONFIRM_CODE");
    },
  });

  return (
    <UiShow>
      <UiShow.When isTrue={step == "IS_READY"}>
        <Wrapper>
          <IconQuestion className="mb-6" />
          <Title>
            {capitalize(
              t(
                LANG_KEYS.CHANGE_YOUR_EMAIL.key,
                LANG_KEYS.CHANGE_YOUR_EMAIL.value,
              ),
            )}
          </Title>
          <Subtitle>
            {t(
              LANG_KEYS.ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_YOUR_EMAIL.key,
              LANG_KEYS.ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_YOUR_EMAIL.value,
            )}
          </Subtitle>
          <Footer>
            <UiButton
              className="flex-1 w-full"
              variant="white"
              onClick={onClose}
            >
              <div>
                {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
              </div>
            </UiButton>
            <UiButton
              onClick={() => startChangeEmailMutation.mutate({})}
              className="flex-1 w-full"
              variant="main"
              loading={startChangeEmailMutation.isPending}
            >
              <div>
                {capitalize(t(LANG_KEYS.CHANGE.key, LANG_KEYS.CHANGE.value))}
              </div>
            </UiButton>
          </Footer>
        </Wrapper>
      </UiShow.When>
      <UiShow.When isTrue={step == "CONFIRM_CODE"}>
        <Wrapper>
          <Form onSubmit={submitFirstCodeConf}>
            <Title>
              {t(
                LANG_KEYS.ENTER_CONFIRMATION_CODE.key,
                LANG_KEYS.ENTER_CONFIRMATION_CODE.value,
              )}
            </Title>
            <Subtitle>
              {t(
                LANG_KEYS.WE_HAVE_SENT_A_CONFIRMATION_CODE_TO_YOUR_CURRENT_EMAIL
                  .key,
                LANG_KEYS.WE_HAVE_SENT_A_CONFIRMATION_CODE_TO_YOUR_CURRENT_EMAIL
                  .value,
              )}
            </Subtitle>
            <div className="my-5">
              <UiInput
                label={t(
                  LANG_KEYS.CONFIRMATION_CODE.key,
                  LANG_KEYS.CONFIRMATION_CODE.value,
                )}
                error={errorsFirstCodeConf?.code?.message}
                {...registerFirstCodeConf("code", {
                  required: {
                    value: true,
                    message: t(
                      LANG_KEYS.REQUIRED.key,
                      LANG_KEYS.REQUIRED.value,
                    ),
                  },
                })}
              />
            </div>
            {invalidFirstCode && (
              <InvalidText>
                {t(
                  LANG_KEYS
                    .AN_INVALID_VALUE_WAS_ENTERED_CHECK_THAT_THE_CONFIRMATION_CODE_IS_ENTERED_CORRECTLY_AND_TRY_AGAIN
                    .key,
                  LANG_KEYS
                    .AN_INVALID_VALUE_WAS_ENTERED_CHECK_THAT_THE_CONFIRMATION_CODE_IS_ENTERED_CORRECTLY_AND_TRY_AGAIN
                    .value,
                )}
              </InvalidText>
            )}
            <Footer>
              <UiButton
                type="button"
                className="flex-1 w-full"
                variant="white"
                onClick={onClose}
              >
                <div>
                  {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
                </div>
              </UiButton>
              <UiButton
                type="submit"
                loading={firstCodeConfLoading}
                className="flex-1 w-full"
                variant="main"
              >
                <div>
                  {capitalize(
                    t(LANG_KEYS.CONFIRM.key, LANG_KEYS.CONFIRM.value),
                  )}
                </div>
              </UiButton>
            </Footer>
          </Form>
        </Wrapper>
      </UiShow.When>
      <UiShow.When isTrue={step == "NEW_EMAIL"}>
        <Wrapper>
          <Form onSubmit={submitNewEmail}>
            <Title>
              {t(
                LANG_KEYS.ENTER_NEW_EMAIL.key,
                LANG_KEYS.ENTER_NEW_EMAIL.value,
              )}
            </Title>
            <Subtitle>
              {t(
                LANG_KEYS.YOUR_ACCOUNT_WILL_BE_LINKED_TO_THE_SPECIFIED_EMAIL
                  .key,
                LANG_KEYS.YOUR_ACCOUNT_WILL_BE_LINKED_TO_THE_SPECIFIED_EMAIL
                  .value,
              )}
            </Subtitle>
            <div className="my-5">
              <UiInput
                label={t(LANG_KEYS.NEW_EMAIL.key, LANG_KEYS.NEW_EMAIL.value)}
                error={errorsNewEmail?.email?.message}
                {...registerNewEmail("email", {
                  required: {
                    value: true,
                    message: t(
                      LANG_KEYS.REQUIRED.key,
                      LANG_KEYS.REQUIRED.value,
                    ),
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t(
                      LANG_KEYS.INVALID_EMAIL_FORMAT.key,
                      LANG_KEYS.INVALID_EMAIL_FORMAT.value,
                    ),
                  },
                })}
              />
            </div>

            <Footer>
              <UiButton
                type="button"
                className="flex-1 w-full"
                variant="white"
                onClick={onClose}
              >
                <div>
                  {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
                </div>
              </UiButton>
              <UiButton
                type="submit"
                loading={newEmailLoading}
                className="flex-1 w-full"
                variant="main"
              >
                <div>
                  {capitalize(
                    t(
                      LANG_KEYS.SET_NEW_EMAIL.key,
                      LANG_KEYS.SET_NEW_EMAIL.value,
                    ),
                  )}
                </div>
              </UiButton>
            </Footer>
          </Form>
        </Wrapper>
      </UiShow.When>
      <UiShow.When isTrue={step == "CONF_NEW_EMAIL"}>
        <Wrapper>
          <Form onSubmit={submitConfNewEmail}>
            <Title>
              {t(LANG_KEYS.FINAL_STEP.key, LANG_KEYS.FINAL_STEP.value)}
            </Title>
            <Subtitle>
              {t(
                LANG_KEYS
                  .A_CONFIRMATION_CODE_HAS_BEEN_SENT_TO_YOUR_NEWLY_PROVIDED_EMAIL_ADDRESS_PLEASE_BE_AWARE_THAT_THIS_ACTION_ONCE_COMPLETED_CANNOT_BE_REVERSED
                  .key,
                LANG_KEYS
                  .A_CONFIRMATION_CODE_HAS_BEEN_SENT_TO_YOUR_NEWLY_PROVIDED_EMAIL_ADDRESS_PLEASE_BE_AWARE_THAT_THIS_ACTION_ONCE_COMPLETED_CANNOT_BE_REVERSED
                  .value,
              )}
            </Subtitle>
            <div className="my-5">
              <UiInput
                label={t(
                  LANG_KEYS.CONFIRMATION_CODE.key,
                  LANG_KEYS.CONFIRMATION_CODE.value,
                )}
                error={errorsConfNewEmail?.code?.message}
                {...registerConfNewEmail("code", {
                  required: {
                    value: true,
                    message: t(
                      LANG_KEYS.REQUIRED.key,
                      LANG_KEYS.REQUIRED.value,
                    ),
                  },
                })}
              />
            </div>
            {invalidSecondCode && (
              <InvalidText>
                {t(
                  LANG_KEYS
                    .AN_INVALID_VALUE_WAS_ENTERED_CHECK_THAT_THE_CONFIRMATION_CODE_IS_ENTERED_CORRECTLY_AND_TRY_AGAIN
                    .key,
                  LANG_KEYS
                    .AN_INVALID_VALUE_WAS_ENTERED_CHECK_THAT_THE_CONFIRMATION_CODE_IS_ENTERED_CORRECTLY_AND_TRY_AGAIN
                    .value,
                )}
              </InvalidText>
            )}
            <Footer>
              <UiButton
                type="button"
                className="flex-1 w-full"
                variant="white"
                onClick={onClose}
              >
                <div>
                  {capitalize(t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value))}
                </div>
              </UiButton>
              <UiButton
                type="submit"
                className="flex-1 w-full"
                variant="main"
                loading={confNewEmailLoading}
              >
                <div>
                  {capitalize(
                    t(LANG_KEYS.CONFIRM.key, LANG_KEYS.CONFIRM.value),
                  )}
                </div>
              </UiButton>
            </Footer>
          </Form>
        </Wrapper>
      </UiShow.When>
    </UiShow>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--dark);
  margin-bottom: 10px;
`;
const Subtitle = styled.h4`
  text-align: center;
  color: #6b7280;
  font-size: 14px;
`;

const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const InvalidText = styled.div`
  text-align: center;
  color: red;
`;

const Form = styled.form`
  width: 100%;
`;
