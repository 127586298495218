import "@/shared/ui/style/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "./localization";

import { ToastContainer } from "react-toastify";
import { PreloadProvider, QueryProvider } from "./providers";
import { RouterSetup } from "./router";
import { BrowserRouter } from "react-router-dom";
import { ModalsWidget } from "@/widgets";

function App() {
  return (
    <QueryProvider>
      <BrowserRouter>
        <PreloadProvider>
          <RouterSetup />
        </PreloadProvider>
        <ToastContainer
          stacked
          closeOnClick
          position="top-center"
          autoClose={1000}
        />
        <ModalsWidget />
      </BrowserRouter>
    </QueryProvider>
  );
}

export default App;
