import {
  EditAccountDtoReq,
  userControllerEditAccountInfo as mutationFn,
  userControllerGetAccountInfo as queryFn,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LANG_KEYS, QUERY_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAutoTranslation } from "@/entities";
import { useNavigate } from "react-router-dom";

type FormData = EditAccountDtoReq;
export function useAccountForm() {
  const { t } = useAutoTranslation();
  const { register, handleSubmit, setValue } = useForm<FormData>({
    values: {
      vocabulary: "",
      webhookUrl: "",
    },
  });
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const accountQuery = useQuery({
    queryFn,
    queryKey: QUERY_KEYS.ACCOUNT,
  });

  const formMutation = useMutation({
    mutationFn,
    async onSuccess(res) {
      queryClient.setQueryData(QUERY_KEYS.ACCOUNT, () => res);
      toast.success(
        t(
          LANG_KEYS.ACCOUNT_SETTINGS_SAVED.key,
          LANG_KEYS.ACCOUNT_SETTINGS_SAVED.value,
        ),
      );
      navigate(ROUTER_PATHS.HOME);
    },
  });

  useEffect(() => {
    if (accountQuery.data) {
      setValue("vocabulary", accountQuery.data.vocabulary);
      setValue("webhookUrl", accountQuery.data.webhookUrl);
    }
  }, [accountQuery.data]);

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate({
        webhookUrl: values.webhookUrl ?? "",
        vocabulary: values.vocabulary ?? "",
      });
    }),
    isLoading: formMutation.isPending,
    accountQuery,
  };
}
