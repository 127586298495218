import { FC } from "react";
import styled from "styled-components";
import { CgClose } from "react-icons/cg";
import { IconChat } from "@/shared/ui/icons";

interface Props {
  onClose?: () => void;
  title: string;
  list: string[];
}

export const UiChatDecor: FC<Props> = ({ onClose, list, title }) => {
  return (
    <Wrapper>
      <CloseBtn type="button">
        <CgClose color="#000" onClick={onClose} size={16} />
      </CloseBtn>

      <Top>
        <IconChat className="w-4 h-4" />
        <p>{title}</p>
      </Top>

      <List>
        {list.map((item, i) => (
          <ListItem key={`chat-decor-item-${i}`}>{item}</ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background-color: rgb(236, 236, 241);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: rgb(33, 37, 41);
  width: fit-content;
`;

const Top = styled.div`
  display: flex;
  gap: 15px;
  font-size: 14px;
`;

const List = styled.ul`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style-type: disc;
`;
const ListItem = styled.li`
  font-size: 14px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;
