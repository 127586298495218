import { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
  letter: string;
  fromAudioNotes?: boolean;
}

export const UiChatMsg: FC<Props> = ({ children, letter, fromAudioNotes }) => {
  return (
    <Wrapper>
      {!fromAudioNotes ? (
        <Avatar>{letter}</Avatar>
      ) : (
        <ServiceLogo src="/logo.svg" alt="" />
      )}
      <Chat>{children}</Chat>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

const Avatar = styled.div`
  margin-top: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
  border-radius: 50%;
  background-color: #dfdfdf;
`;
const ServiceLogo = styled.img`
  margin-top: 10px;
  width: 32px;
  height: 32px;
  object-fit: contain;
`;
const Chat = styled.div`
  background-color: rgb(245, 245, 245);
  padding: 16px;
  border-radius: 16px;
`;
