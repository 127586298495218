import styled from "styled-components";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";
import { PricingLifetimeCards, PricingSubscriptionCards } from "@/widgets";
import { useAutoTranslation } from "@/entities";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const PricingPage = () => {
  const { t } = useAutoTranslation();
  return (
    <Elements stripe={stripePromise}>
      <section>
        <div className="theContainer">
          <Headings>
            <Subtitle>
              {capitalize(t(LANG_KEYS.PRICING.key, LANG_KEYS.PRICING.value))}
            </Subtitle>
            <Title>
              {capitalize(
                t(
                  LANG_KEYS
                    .GET_THE_AI_COPILOT_FOR_CLEAR_ACTIONABLE_NOTES_FROM_MEETINGS_AND_LECTURES
                    .key,
                  LANG_KEYS
                    .GET_THE_AI_COPILOT_FOR_CLEAR_ACTIONABLE_NOTES_FROM_MEETINGS_AND_LECTURES
                    .value,
                ),
              )}
            </Title>
            <Description>
              {capitalize(
                t(
                  LANG_KEYS
                    .SAVE_TIME_EXTRACT_KEY_POINTS_AND_GAIN_DEEPER_UNDERSTANDING_WITH_AI_POWERED_ANALYSIS
                    .key,
                  LANG_KEYS
                    .SAVE_TIME_EXTRACT_KEY_POINTS_AND_GAIN_DEEPER_UNDERSTANDING_WITH_AI_POWERED_ANALYSIS
                    .value,
                ),
              )}
            </Description>
          </Headings>
          <PricingSubscriptionCards />

          <LifeTimeWrapper>
            <Heading>
              {capitalize(
                t(
                  LANG_KEYS.OR_CHOOSE_A_ONE_TIME_LIFETIME_PAYMENT.key,
                  LANG_KEYS.OR_CHOOSE_A_ONE_TIME_LIFETIME_PAYMENT.value,
                ),
              )}
            </Heading>
            <PricingLifetimeCards />
          </LifeTimeWrapper>
        </div>
      </section>
    </Elements>
  );
};

const Subtitle = styled.h2`
  color: var(--main-purple);
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 4px;
`;

const Title = styled.h1`
  color: #111827;
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  max-width: 768px;
  word-break: break-word;
  margin: 0 auto 15px;
`;

const Description = styled.p`
  color: #4b5563;
  font-size: 16px;
  text-align: center;
`;

const Headings = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h3`
  font-weight: 700;
  color: #111827;
  font-size: 36px;
  text-align: center;
`;

const LifeTimeWrapper = styled.div`
  margin: 80px 0;
  display: flex;
  flex-direction: column;
`;
