import { forwardRef, PropsWithRef, TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import { UiValidationError } from "./ui-validation-error";
import { StyledTextArea } from "./styled";

type Props = {
  name: string;
  label?: string;
  value?: string;
  error?: string;
  type?: "text" | "password" | "number" | "email";
  wrapperClassname?: string;
  placeholder?: string;
} & PropsWithRef<TextareaHTMLAttributes<HTMLTextAreaElement>>;

export const UiTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { placeholder = "", wrapperClassname, error, name, value, label, ...props },
    ref,
  ) => {
    return (
      <Wrapper className={wrapperClassname}>
        {!!label && <Label htmlFor={name}>{label}</Label>}
        <Textarea
          ref={ref}
          value={value}
          name={name}
          id={name}
          placeholder={placeholder}
          $error={!!error}
          {...props}
        />
        {!!error && <UiValidationError message={error} />}
      </Wrapper>
    );
  },
);

UiTextarea.displayName = "UiTextarea";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
`;

const Textarea = styled(StyledTextArea)`
  min-height: 140px;
`;
