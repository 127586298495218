import styled from "styled-components";
import { FC, ReactNode } from "react";

interface Props {
	children: ReactNode;
	label?: string;
	btnClassname?: string;
	onClick?: () => void;
}

export const UiNoteActionWrapper: FC<Props> = ({
	children,
	label,
	onClick,
	btnClassname,
}) => {
	return (
		<Wrapper>
			{label && <Label>{label}</Label>}
			<Button type="button" onClick={onClick} className={btnClassname}>
				{children}
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	z-index: 0;

	&:hover span {
		opacity: 1;
		visibility: visible;
	}
`;

const Label = styled.span`
	top: -35px;
	position: absolute;
	background-color: black;
	color: white;
	padding: 5px 7px;
	border-radius: 4px;
	font-size: 14px;
	white-space: nowrap;
	opacity: 0;
	visibility: hidden;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

	&::after {
		content: "";
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
		border-width: 5px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}
`;

const Button = styled.button`
	background-color: rgb(249, 250, 251);
	width: 40px;
	height: 40px;
	display: flex;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	transition: all 0.6s ease;

	&:active {
		transform: scale(0.75);
	}

	&:hover {
		background-color: rgb(230, 231, 233);
	}

	img {
		width: 20px;
		height: 20px;
	}
`;
