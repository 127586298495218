import {
  LANG_KEYS,
  ROUTER_PATHS,
  SUPPORTED_FILE_FORMATS,
} from "@/shared/constants";
import { ChangeEvent, ComponentType, FC, ReactNode, useRef } from "react";
import { ConvertFileSize, getBase64FromFile } from "@/shared/utils";
import {
  useAutoTranslation,
  useRecordProcessStore,
  useUserStore,
  useUtilityStore,
} from "@/entities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TranscribeEnum, UserPlan } from "@/shared/types.ts";
import {
  SUPPORTED_AUDIO_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
} from "@/shared/constants/supported-file-formats.ts";
import { checkAudioDuration } from "@/shared/utils/check-audio-duration.ts";

interface Props {
  children: ReactNode;
  btnClassname?: string;
  callback?: () => void;
  wrapper?:
    | ComponentType<{ children: ReactNode }>
    | keyof JSX.IntrinsicElements;
}

export const FileTranscribe: FC<Props> = ({
  children,
  btnClassname,
  callback,
  wrapper: Wrapper = "div",
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { startTranscribe, transcribing } = useRecordProcessStore();
  const { email, plan } = useUserStore();
  const {
    setRegisterForFileAlert,
    setFileLimitSizeAlert,
    setMaxFileLimitAlert,
    setLengthAudioSizeError,
    clearAll,
  } = useUtilityStore();
  const navigate = useNavigate();
  const { t } = useAutoTranslation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    if (!email) return;

    const file = e.target.files[0];
    if (!file) return;

    clearAll();

    const freeUserMoreThanFiveMb =
      plan == UserPlan.FREE && file.size > ConvertFileSize.MBtoBytes(5);

    setFileLimitSizeAlert({ isFileLimitSizeAlert: freeUserMoreThanFiveMb });
    if (freeUserMoreThanFiveMb) return;

    const isMaxFileLimitAlert = file.size > ConvertFileSize.MBtoBytes(400);
    setMaxFileLimitAlert({ isMaxFileLimitAlert });
    if (isMaxFileLimitAlert) return;

    const nameArr = file.name.split(".");
    const extension = nameArr.pop()!.toLowerCase();
    if (!SUPPORTED_FILE_FORMATS.includes(extension)) {
      toast.error(
        t(
          LANG_KEYS.UNSUPPORTED_FILE_FORMAT.key,
          LANG_KEYS.UNSUPPORTED_FILE_FORMAT.value,
        ),
      );
      return;
    }

    const checkVideoDuration = async (
      file,
    ): Promise<{ fileDuration: undefined | number; message: string }> => {
      return await new Promise((resolve) => {
        const video = document.createElement("video");
        const objectUrl = URL.createObjectURL(file);
        video.src = objectUrl;

        video.onloadedmetadata = () => {
          if (video.duration < 3) {
            URL.revokeObjectURL(objectUrl);

            resolve({ fileDuration: video.duration, message: "Less than 3" });
          }
          resolve({ fileDuration: video.duration, message: "More than 3" });
        };

        video.onerror = () => {
          URL.revokeObjectURL(objectUrl);

          resolve({
            message: "Error loading video file.",
            fileDuration: undefined,
          });
        };
      });
    };

    if (SUPPORTED_AUDIO_FORMATS.includes(extension)) {
      // check audio
      const result = await checkAudioDuration(file);

      if (typeof result.fileDuration == "number" && result.fileDuration < 3) {
        clearAll();
        setLengthAudioSizeError({ isLengthAudioSizeError: true });
        return;
      }
    }

    if (SUPPORTED_VIDEO_FORMATS.includes(extension)) {
      //check video
      const result = await checkVideoDuration(file);
      if (typeof result.fileDuration == "number" && result.fileDuration < 3) {
        clearAll();
        setLengthAudioSizeError({ isLengthAudioSizeError: true });
        return;
      }
    }

    setLengthAudioSizeError({ isLengthAudioSizeError: false });

    navigate(ROUTER_PATHS.HOME);
    const base64 = await getBase64FromFile(file);

    startTranscribe({ base64, extension, type: TranscribeEnum.FILE });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    if (!email) {
      if (callback) callback();
      clearAll();
      setRegisterForFileAlert({ isRegisterForFileAlert: true });
      return;
    }

    if (fileInputRef.current && !transcribing) {
      fileInputRef.current.click();
    }
  };

  return (
    <Wrapper>
      <button
        type="button"
        className={btnClassname}
        onClick={handleButtonClick}
      >
        {children}
      </button>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept={SUPPORTED_FILE_FORMATS.map((ext) => `.${ext}`).join(",")}
      />
    </Wrapper>
  );
};
