import { FC } from "react";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";
import { UiShow } from "@/shared/ui/ui-show.tsx";
import { FaRegCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { UiLetterAnimation } from "@/shared/ui/ui-letter-animation.tsx";
import { capitalize } from "@/shared/utils";

interface Props {
  title: string;
  done: boolean;
  current?: boolean;
  text: string;
}
const iconSize = 20;
export const UiSocketStep: FC<Props> = ({ title, done, current, text }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <UiShow>
          <UiShow.When isTrue={!!current}>
            <Oval
              strokeWidth={5}
              width={iconSize}
              height={iconSize}
              color="#fff"
            />
          </UiShow.When>
          <UiShow.When isTrue={!done}>
            <FaRegCircle size={iconSize} color="#fff" />
          </UiShow.When>
          <UiShow.When isTrue={done}>
            <FaRegCircleCheck size={iconSize} color="#fff" />
          </UiShow.When>
        </UiShow>
        <Title>{capitalize(title).replace("wav", "WAV")}</Title>
      </IconWrapper>
      {text ? (
        <Text>
          {!done ? <UiLetterAnimation typingSpeed={30} text={text} /> : text}
        </Text>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 14px;
`;

const Text = styled.p`
  font-size: 14px;
  padding: 12px 50px;
  white-space: pre-wrap;

  @media screen and (max-width: 991px) {
    width: 95%;
    padding: 12px;
  }
`;
