import styled from "styled-components";
import { CgClose } from "react-icons/cg";
import { useAutoTranslation, useTextNoteStore } from "@/entities";
import { useEffect } from "react";
import { UiButton } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { useTextNoteForm } from "./model.ts";
import { Controller } from "react-hook-form";
import { capitalize } from "@/shared/utils";

export const TextForm = () => {
  const { closeTextNote } = useTextNoteStore();
  const { t } = useAutoTranslation();
  const { control, handleSubmit } = useTextNoteForm();

  const handleKeyboardPress = (e: any) => {
    if (e.keyCode == 27 || e.key == "Escape") {
      closeTextNote();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPress);
    // BodyNoScroll.Enable();
    return () => {
      window.removeEventListener("keydown", handleKeyboardPress);
      // BodyNoScroll.Disable();
    };
  }, []);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <CloseBtn type="button" onClick={closeTextNote}>
        <CgClose color="#000" size={28} />
      </CloseBtn>

      <Content>
        <Controller
          control={control}
          name="original"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              name={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t(
                LANG_KEYS.TEXT_NOTE_PLACEHOLDER.key,
                LANG_KEYS.TEXT_NOTE_PLACEHOLDER.value,
              )}
            />
          )}
        />
      </Content>
      <Bottom>
        <UiButton variant="main" type="submit">
          <div>{capitalize(t(LANG_KEYS.SAVE.key, LANG_KEYS.SAVE.value))}</div>
        </UiButton>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  background-color: #fff;
  border-radius: 22px;
  border: 1px solid rgb(222, 226, 230);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  gap: 35px;
  position: relative;
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 14px;
`;

const Content = styled.div`
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.textarea`
  resize: none;
  height: 95%;
  width: 95%;
  outline: none;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;
