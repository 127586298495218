import { FC, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import { UiLink } from "@/shared/ui/ui-link.tsx";

type ContentPosition = "left" | "center" | "right";

interface Props {
  children?: ReactNode;
  contentPosition?: ContentPosition;
  text: string;
}

export const UiDropMenu: FC<Props> = ({
  children,
  text,
  contentPosition = "center",
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<any>();

  const toggle = () => setOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !wrapperRef.current ||
      wrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Trigger onClick={toggle}>
        <UiLink linkHover={false}>{text}</UiLink>
        <Arrow $isOpen={isOpen}>
          <FaChevronDown color="#9ca3af" size={12} />
        </Arrow>
      </Trigger>
      <Content $isOpen={isOpen} $contentPosition={contentPosition}>
        {children}
      </Content>
    </Wrapper>
  );
};

interface ArrowProps {
  $isOpen: boolean;
}
interface ContentProps {
  $isOpen: boolean;
  $contentPosition: ContentPosition;
}

const Wrapper = styled.div`
  position: relative;
`;
const Trigger = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
const Content = styled.div<ContentProps>`
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.176) 0 16px 48px 0;
  background-color: #fff;
  border-radius: 6px;
  top: calc(100% + 20px);
  border: 1px solid white;
  z-index: 10;

  width: 224px;

  display: ${(props) => (props.$isOpen ? "block" : "none")};

  ${(props) =>
    props.$contentPosition === "left" &&
    `
    left: -100%;
    transform: translateX(-65px);
  `}

  ${(props) =>
    props.$contentPosition === "center" &&
    `
    left: 50%;
    transform: translateX(-50%);
  `}

    ${(props) =>
    props.$contentPosition === "right" &&
    `
    right: 0;
    transform: none;
  `}
`;

const Arrow = styled.div<ArrowProps>`
  transition: all 0.3s ease;
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;
