import { ReactNode, useEffect, useState } from "react";
import { UiLoading, UiShow } from "@/shared/ui";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion as m } from "framer-motion";
import { useLangStore, useRecordOptionsStore, useUserStore } from "@/entities";
import {
  authControllerUpdateMyToken,
  stripeControllerCheckSubscriptionExpiration,
  translationControllerGetLangsList,
} from "@/shared/api/generated";
import { getDictionary } from "@/shared/api/dictionary.ts";
import { useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "@/shared/constants";

export const PreloadProvider = ({ children }: { children: ReactNode }) => {
  const [isDone, setDone] = useState(false);
  const { i18n } = useTranslation();
  const { setData, lang } = useLangStore();
  const [searchParams] = useSearchParams();
  const {
    client_access_token,
    access_token,
    generateClientAuth,
    login,
    email,
  } = useUserStore();

  const { invalidateOptions } = useRecordOptionsStore();

  const handlePreload = async () => {
    const res = await translationControllerGetLangsList();
    setData(res);
    const currentDictionary = await getDictionary(lang);
    i18n.addResources(lang, "translation", currentDictionary);
    await i18n.changeLanguage(lang);

    if (!email) {
      let token = "";
      if (!client_access_token) {
        token = await generateClientAuth();
      } else {
        token = access_token;
      }
      login(token);
    } else {
      const updatedToken = await authControllerUpdateMyToken();
      login(updatedToken.access_token);
    }

    await invalidateOptions();
    const expirationRes = await stripeControllerCheckSubscriptionExpiration();

    if (expirationRes.expired && expirationRes.access_token) {
      login(expirationRes.access_token);
    }

    const telegramParam = searchParams.get("telegram");
    if (telegramParam) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID, telegramParam);
    }

    setDone(true);
  };

  useEffect(() => {
    handlePreload();
  }, []);

  return (
    <>
      <UiShow>
        <UiShow.When isTrue={!isDone}>
          <AnimatePresence mode="wait">
            <m.div
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              <UiLoading fullScreen />
            </m.div>
          </AnimatePresence>
        </UiShow.When>
        <UiShow.Else>
          <AnimatePresence mode="wait">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              {children}
            </m.div>
          </AnimatePresence>
        </UiShow.Else>
      </UiShow>
    </>
  );
};
