import { FC, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";

interface Props {
  titles: string[];
  activeIdx: number;
  onToggle: (activeIdx: number, title: string) => void;
}

export const UiSmallTabs: FC<Props> = ({ activeIdx, titles, onToggle }) => {
  const [uniqueKey] = useState<string>(v4());
  const [currentIdx, setCurrentIdx] = useState<number>(activeIdx);

  const handleClick = (idx: number) => {
    setCurrentIdx(idx);
    onToggle(idx, titles[idx]);
  };

  return (
    <Wrapper>
      {titles.map((el, i) => (
        <Item
          onClick={() => handleClick(i)}
          $isActive={i == currentIdx}
          key={`small-tab-${uniqueKey}-${i}`}
        >
          {el}
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid #e5e7eb;
  border-radius: 30px;
  padding: 4px;
  gap: 5px;
`;

const Item = styled.div<{ $isActive: boolean }>`
  padding: 4px 10px;
  border-radius: 30px;
  transition: all 0.3s ease;
  color: ${(props) => (props.$isActive ? "#fff" : "#6b7280")};
  background-color: ${(props) => (props.$isActive ? "#4f46e5" : "transparent")};
  font-size: 12px;
  font-weight: 600;
`;
