import {
  LANG_KEYS,
  QUERY_KEYS,
  TRANSCRIBE_SOCKET_COMMANDS,
} from "@/shared/constants";
import { useEffect, useRef, useState } from "react";
import {
  useEditNoteStore,
  useRecordProcessStore,
  useTextNoteStore,
  useUserStore,
} from "@/entities";
import { io, Socket } from "socket.io-client";
import { useQueryClient } from "@tanstack/react-query";
import { Note } from "@/shared/api/generated.ts";
import { markDone, StepType, updateText } from "./common.ts";
import { TRANSCRIBE_SOCKET_ERRORS } from "@/shared/constants/socket-commands.ts";

const ALL_STEPS: StepType[] = [
  {
    data: LANG_KEYS.CONNECTING_TO_SERVER,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.INSPECTING_FILE,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.CONVERTING_TO_WAV,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.LANGUAGE_DETECTION,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_ORIGINAL_TEXT,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_PARAGRAPH_FORM,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_BULLET_POINTS,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_TITLE,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.SAVE_FILE,
    done: false,
    text: "",
  },
];

export function useTranscribeProcess() {
  const url = import.meta.env.VITE_TRANCRIBE_SOCKET_URL;
  const { base64, extension, transcribing, type, endTranscribe } =
    useRecordProcessStore();
  const { original } = useTextNoteStore();
  const [percent, setPercent] = useState(0);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [steps, setSteps] = useState<StepType[]>(ALL_STEPS);
  const { access_token } = useUserStore();
  const queryClient = useQueryClient();
  const { openEdit } = useEditNoteStore();
  const socket = useRef<Socket>(
    io(url, {
      autoConnect: false,
      auth: {
        token: access_token,
      },
      transports: ["websocket"],
      upgrade: false,
    }),
  ).current;

  useEffect(() => {
    if (transcribing && !original) {
      setPercent(0);
      setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
      socket.connect();

      const handleDisconnect = () => {
        setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
        setCurrentIdx(0);
        endTranscribe();
        socket.removeAllListeners();
      };

      const setupListeners = () => {
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.CONNECTED, () => {
          socket.emit("new-process");
        });

        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.NEW_PROCESS_ID,
          ({ data }: any) => {
            const parsed = JSON.parse(data) as { processId: string };
            socket.emit("start", {
              base64,
              extension,
              type,
              processId: parsed.processId,
            });
            setCurrentIdx(1);
            setSteps((prev) => {
              return markDone(prev, 0, "");
            });
          },
        );

        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_INSPECTING_FILE, () => {
          setCurrentIdx(2);
          setSteps((prev) => {
            return markDone(prev, 1, "");
          });
        });

        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_WAV_CONVERTING, () => {
          setCurrentIdx(3);
          setSteps((prev) => {
            return markDone(prev, 2, "");
          });
        });

        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_LANG_DETECTION, () => {
          setCurrentIdx(4);
          setSteps((prev) => {
            return markDone(prev, 3, "");
          });
        });

        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.CHUNK_TRANSCRIBING,
          ({ data }: any) => {
            setSteps((prev) => {
              return updateText(prev, 4, data);
            });
          },
        );

        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.DONE_ORIGINAL_TEXT,
          ({ data }: any) => {
            setCurrentIdx(5);
            setSteps((prev) => {
              return markDone(prev, 4, data);
            });
          },
        );
        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.DONE_PARAGRAPH_FORM,
          ({ data }: any) => {
            setCurrentIdx(6);
            setSteps((prev) => {
              return markDone(prev, 5, data);
            });
          },
        );
        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.DONE_BULLET_POINTS,
          ({ data }: any) => {
            setCurrentIdx(7);
            setSteps((prev) => {
              return markDone(prev, 6, data);
            });
          },
        );
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_TITLE, ({ data }: any) => {
          setCurrentIdx(8);
          setSteps((prev) => {
            return markDone(prev, 7, data);
          });
        });
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_FILE, ({ data }: any) => {
          const notes = JSON.parse(data) as { all: Note[]; newNote: Note };
          queryClient.setQueryData(QUERY_KEYS.NOTES, () => notes.all);
          setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
          setCurrentIdx(0);
          endTranscribe();
          openEdit({ note: notes.newNote });
        });

        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.PROGRESS_PERCENT,
          ({ data }: any) => {
            const res = JSON.parse(data) as { percent: number };
            setPercent(res.percent);
          },
        );

        for (const exception of TRANSCRIBE_SOCKET_ERRORS) {
          socket.on(exception, () => {
            // console.log(exception);
            handleDisconnect();
          });
        }
      };

      setupListeners();

      socket.on("disconnect", () => {
        handleDisconnect();
      });
    }

    return () => {
      socket.disconnect();
      socket.removeAllListeners();
    };
  }, [transcribing]);

  return { steps, currentIdx, percent };
}
