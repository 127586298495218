import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "@/shared/constants";
import { useSearchNotesStore } from "@/entities";

type FormData = { searchSavedNotes: string };
export function useSearchNoteForm() {
  const { handleSubmit, reset, setValue, getValues, control, watch } =
    useForm<FormData>({ defaultValues: { searchSavedNotes: "" } });

  const { closeModal } = useSearchNotesStore();

  const navigate = useNavigate();

  return {
    handleSubmit: handleSubmit((values) => {
      navigate(ROUTER_PATHS.HOME, { state: { q: values.searchSavedNotes } });
      reset();
      closeModal();
    }),
    defaultValues: getValues(),
    setValue,
    control,
    watch,
  };
}
