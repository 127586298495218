import { forwardRef, InputHTMLAttributes, PropsWithRef } from "react";
import styled from "styled-components";
import { CgClose, CgSearch } from "react-icons/cg";

type Props = {
  name: string;
  value?: string;
  error?: string;
  placeholder?: string;
  onSearch?: () => void;
  onClear?: () => void;
} & PropsWithRef<InputHTMLAttributes<HTMLInputElement>>;

export const UiInputSearch = forwardRef<HTMLInputElement, Props>(
  ({ placeholder = "", onSearch, onClear, name, value, ...props }, ref) => {
    return (
      <Wrapper>
        <Input
          className="text-base text-slate-900 placeholder:text-slate-600 rounded-lg shadow-md bg-white"
          ref={ref}
          value={value}
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          {...props}
        />
        {value && (
          <IconButton onClick={onClear} $right="50px" type="button">
            <CgClose color="gray" size={28} />
          </IconButton>
        )}
        <IconButton onClick={onSearch} $right="20px" type="button">
          <CgSearch color="gray" size={26} />
        </IconButton>
      </Wrapper>
    );
  },
);

UiInputSearch.displayName = "UiInputSearch";

const Wrapper = styled.div`
  position: relative;
  height: 56px;
`;

const Input = styled.input`
  height: 56px;
  padding: 16px 24px;
  outline: none;
  font-size: 14px;
  width: 470px;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }
`;

const IconButton = styled.button<{ $right: string }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${(props) => props.$right};
`;
