export const getZapierHtml = (email: string) =>
  `
      <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>Zappier</title>
<link
rel="stylesheet"
href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"
/>

<style>
.scroll {
flex-direction: row;
}
</style>
</head>
<body>
<zapier-zap-templates
sign-up-email="${email}"
sign-up-first-name="First Name"
sign-up-last-name="Last Name"
client-id="MMHGSwPJd21AvOC7MI0zSRjq25eWirl9GTHXBm9Q"
theme="light"
apps="audionotesai"
create-without-template="hide"
limit="1"
use-this-zap="show"
></zapier-zap-templates>

<script
type="module"
src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"
></script>
</body>
</html>
  `;
