import { UiHeading } from "@/shared/ui";
import { NotesFavorite } from "@/widgets";
import styled from "styled-components";
import { useAutoTranslation, useUserStore } from "@/entities";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const FavoriteNotesPage = () => {
  const { t } = useAutoTranslation();
  const { email } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(ROUTER_PATHS.HOME, { replace: true });
    }
  }, [email]);

  return (
    <Section>
      <Wrapper>
        <UiHeading>
          {t(
            LANG_KEYS.YOUR_FAVORITE_NOTES.key,
            LANG_KEYS.YOUR_FAVORITE_NOTES.value,
          )}
        </UiHeading>
        <NotesFavorite />
      </Wrapper>
    </Section>
  );
};

const Section = styled.section``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
