import { useChatNoteStore, useLangStore, useUserStore } from "@/entities";
import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { CHAT_SOCKET_COMMANDS } from "@/shared/constants";
import { useForm } from "react-hook-form";

type FormData = { message: string };
export function useChatNoteForm(noteId: string) {
  const url = import.meta.env.VITE_CHAT_SOCKET_URL;
  const { lang } = useLangStore();
  const chatNoteStore = useChatNoteStore();

  const { access_token } = useUserStore();
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [chat, setChat] = useState<string[]>([]);
  const socket = useRef<Socket>(
    io(url, {
      autoConnect: false,
      forceNew: true,
      auth: {
        token: access_token,
      },
    }),
  ).current;

  const { control, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { message: "" },
  });

  const addMessage = (msg: string) => {
    setChat((prev) => [...prev, msg]);
  };

  const defaultPayload = {
    noteId,
    activeLang: lang,
    message: "",
  };

  useEffect(() => {
    if (chatNoteStore.noteId && !socket.connected) {
      socket.connect();

      const setupListeners = () => {
        socket.on(CHAT_SOCKET_COMMANDS.CONNECTED, () => {
          setConnected(true);
        });

        socket.on(CHAT_SOCKET_COMMANDS.CHAT_RESPONSE, ({ data }: any) => {
          setLoading(false);
          const res = JSON.parse(data) as { response: string };
          addMessage(res.response ? res.response : "Something went wrong");
        });
      };

      setupListeners();

      socket.on("disconnect", () => {
        socket.removeAllListeners();
      });
    }

    return () => {
      if (socket.connected) {
        socket.removeAllListeners();
        socket.disconnect();
      }
    };
  }, [chatNoteStore]);

  return {
    isLoading: loading,
    connected,
    chat,
    control,
    startConnection: socket.connect,
    handleSubmit: handleSubmit((values) => {
      if (!values.message.trim()) return;
      addMessage(values.message);

      socket.emit("message", {
        ...defaultPayload,
        message: values.message,
      });

      setLoading(true);
      reset();
    }),
  };
}
