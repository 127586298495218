import { create } from "zustand";
import { devtools } from "zustand/middleware";

type SetNoteLimitedAlertPayload = {
  isNoteLimitedAlert: boolean;
};

type SetMaxFileLimitAlert = {
  isMaxFileLimitAlert: boolean;
};

type SetRecordPermissionDeclined = {
  isRecordPermissionDeclined: boolean;
};

type SetLengthAudioSizeError = {
  isLengthAudioSizeError: boolean;
};

type SetFileSizeLimitAlert = {
  isFileLimitSizeAlert: boolean;
};

type SetRegisterForFileAlert = {
  isRegisterForFileAlert: boolean;
};

type Store = {
  setRegisterForFileAlert: (arg: SetRegisterForFileAlert) => void;
  isRegisterForFileAlert: boolean;

  setNoteLimitedAlert: (arg: SetNoteLimitedAlertPayload) => void;
  isNoteLimitedAlert: boolean;

  isFileLimitSizeAlert: boolean;
  setFileLimitSizeAlert: (arg: SetFileSizeLimitAlert) => void;

  isMaxFileLimitAlert: boolean;
  setMaxFileLimitAlert: (arg: SetMaxFileLimitAlert) => void;

  isRecordPermissionDeclined: boolean;
  setRecordPermissionDeclined: (arg: SetRecordPermissionDeclined) => void;

  isLengthAudioSizeError: boolean;
  setLengthAudioSizeError: (arg: SetLengthAudioSizeError) => void;

  clearAll: () => void;
};

export const useUtilityStore = create<Store>()(
  devtools(
    (set) => ({
      clearAll: () => {
        set(() => ({
          isRegisterForFileAlert: false,
          isNoteLimitedAlert: false,
          isFileLimitSizeAlert: false,
          isMaxFileLimitAlert: false,
          isLengthAudioSizeError: false,
          isRecordPermissionDeclined: false,
        }));
      },

      isLengthAudioSizeError: false,
      setLengthAudioSizeError: (arg: SetLengthAudioSizeError) => {
        set(() => ({
          isLengthAudioSizeError: arg.isLengthAudioSizeError,
        }));
      },

      isRecordPermissionDeclined: false,
      setRecordPermissionDeclined: (arg: SetRecordPermissionDeclined) => {
        set(() => ({
          isRecordPermissionDeclined: arg.isRecordPermissionDeclined,
        }));
      },

      isMaxFileLimitAlert: false,
      setMaxFileLimitAlert: (arg: SetMaxFileLimitAlert) => {
        set(() => ({
          isMaxFileLimitAlert: arg.isMaxFileLimitAlert,
        }));
      },

      setRegisterForFileAlert: (arg: SetRegisterForFileAlert) => {
        set(() => ({
          isRegisterForFileAlert: arg.isRegisterForFileAlert,
        }));
      },
      isRegisterForFileAlert: false,

      isNoteLimitedAlert: false,
      setNoteLimitedAlert: (arg: SetNoteLimitedAlertPayload) => {
        set(() => ({
          isNoteLimitedAlert: arg.isNoteLimitedAlert,
        }));
      },

      isFileLimitSizeAlert: false,
      setFileLimitSizeAlert: (arg: SetFileSizeLimitAlert) => {
        set(() => ({
          isFileLimitSizeAlert: arg.isFileLimitSizeAlert,
        }));
      },
    }),
    { name: "UtilityAppStore" },
  ),
);
