import styled from "styled-components";
import { FC, ReactNode } from "react";
import { IconChecked } from "@/shared/ui/icons";

export const List = styled.ul`
  display: grid;
  column-gap: 40px;
  row-gap: 15px;
  grid-template-columns: repeat(2, auto);

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

export const Item = styled.li`
  color: #4b5563;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface Props {
  children: ReactNode;
}

export const ListItem: FC<Props> = ({ children }) => {
  return (
    <Item>
      <IconChecked />
      <p> {children}</p>
    </Item>
  );
};
