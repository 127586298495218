import styled from "styled-components";

export const Title = styled.h4`
  color: rgb(17, 24, 39);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;

export const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const Text = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgb(107, 114, 128);
`;
