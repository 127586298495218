import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { authControllerValidateResetPasswordCode } from "@/shared/api/generated.ts";
import { UiLoading, UiShow } from "@/shared/ui";
import { ResetProcessPasswordForm } from "@/features";
import { ROUTER_PATHS } from "@/shared/constants";

export const ProcessResetPasswordPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const validateCode = async () => {
      if (!code) {
        navigate(ROUTER_PATHS.HOME, { replace: true });
        return;
      }

      try {
        const { email } = await authControllerValidateResetPasswordCode(code);
        setEmail(email);
      } catch (e) {
        console.log(e);
        navigate(ROUTER_PATHS.HOME, { replace: true });
      }
    };

    validateCode();
  }, [code]);

  return (
    <UiShow>
      <UiShow.When isTrue={!email}>
        <UiLoading fullScreen />
      </UiShow.When>
      <UiShow.When isTrue={!!email && !!code}>
        <ResetProcessPasswordForm code={code!} email={email} />
      </UiShow.When>
    </UiShow>
  );
};
