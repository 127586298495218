import {
  authControllerSignIn as mutationFn,
  AuthDtoReq,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useRecordOptionsStore, useUserStore } from "@/entities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/shared/constants";

type FormData = AuthDtoReq;
export function useSignInForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { login } = useUserStore();
  const { invalidateOptions } = useRecordOptionsStore();

  const queryClient = useQueryClient();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess({ access_token }) {
      login(access_token);
      reset();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
      await invalidateOptions();
    },
  });

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
