import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { SelectOption } from "../types";
import { v4 } from "uuid";
import styled from "styled-components";
import { HiChevronUpDown } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa";
import { capitalize } from "@/shared/utils";

interface Props {
  options: SelectOption[];
  value?: string;
  defaultValue?: string;
  onSelect?: (option: SelectOption) => void;
  hint?: string;
  heading?: string;
  label?: string;
  children?: ReactNode;
  closeOnChildrenClick?: boolean;
  secondOptions?: SelectOption[];
  secondHeading?: string;
  showTop?: boolean;
}

export const UiSelectable: FC<Props> = ({
  value,
  options,
  defaultValue,
  heading,
  hint,
  label,
  children,
  secondHeading,
  secondOptions,
  closeOnChildrenClick = true,
  showTop,
  onSelect,
}) => {
  const getFirstValue = (): SelectOption => {
    if (defaultValue) {
      const arr = secondOptions ? [...options, ...secondOptions] : options;

      const findByDefault = arr.find((item) => item.value == defaultValue);
      return findByDefault ?? options[0];
    }
    return options[0];
  };

  const [uniqueKey] = useState<string>(v4());
  const [isOpen, setOpen] = useState(false);

  const [currentValue, setValue] = useState<SelectOption>(getFirstValue());

  const wrapperRef = useRef<any>();

  const toggle = () => setOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !wrapperRef.current ||
      wrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleSelect = (value: SelectOption) => {
    setOpen(false);
    setValue(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  const handleChildrenWrapperClick = () => {
    if (!closeOnChildrenClick) return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value !== undefined) {
      const allOptions = secondOptions
        ? [...options, ...secondOptions]
        : options;
      const newValue = allOptions.find((option) => option.value === value);
      if (newValue) {
        setValue(newValue);
      }
    }
  }, [value, options, secondOptions]);

  return (
    <div ref={wrapperRef} className="relative">
      {!!label && <Label>{label}</Label>}
      <Trigger className="truncate" onClick={toggle}>
        {capitalize(currentValue.name)}
        <HiChevronUpDown size={20} />
      </Trigger>
      {!!hint && <Hint>{hint}</Hint>}

      <Options $open={isOpen} $showTop={!!showTop}>
        {!!heading && <Heading>{heading}</Heading>}

        {options.map((item, i) => (
          <OptionsItem
            onClick={() => handleSelect(item)}
            key={uniqueKey + "-" + i + "-option"}
          >
            <div>{capitalize(item.name)}</div>
            <div>
              {item.value == currentValue.value ? (
                <FaCheck size={15} color="#4f46e5" />
              ) : null}
            </div>
          </OptionsItem>
        ))}

        {!!secondHeading && <Heading>{secondHeading}</Heading>}

        {secondOptions
          ? secondOptions.map((item, i) => (
              <OptionsItem
                onClick={() => handleSelect(item)}
                key={uniqueKey + "-" + i + "-option"}
              >
                <div>{item.name}</div>
                <div>
                  {item.value == currentValue.value ? (
                    <FaCheck size={15} color="#4f46e5" />
                  ) : null}
                </div>
              </OptionsItem>
            ))
          : null}

        <div onClick={handleChildrenWrapperClick}>{children}</div>
      </Options>
    </div>
  );
};

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--dark);
`;

const Trigger = styled.div`
  cursor: pointer;
  padding: 6px 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.075) 0 2px 4px 0;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Hint = styled.div`
  margin-top: 5px;
  color: #6b7280;
  font-size: 13px;
`;

const Heading = styled.div`
  padding: 18px 8px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`;

const Options = styled.div<{ $open: boolean; $showTop: boolean }>`
  box-shadow: rgba(0, 0, 0, 0.075) 0 2px 4px 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  top: ${(props) =>
    props.$showTop ? "calc(-100% - 25px)" : "calc(100% + 1px)"};
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid white;
  z-index: 10;
  flex-direction: column;
  max-height: 270px;
  overflow-y: auto;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  display: ${(props) => (props.$open ? "flex" : "none")};
`;

const OptionsItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
